export const STOCK_ON_HAND_API = "/SS_WebApi/EnvironmentSOHByDate";
export const STOCK_STATUS_API = "/IUBVIUI_WebApi/ProgramProducts";
export const RDF_STOCK_STATUS_API = "/IUBVIUI_WebApi/ProgramProducts?Environment";
export const PO_SHIPMENT_NEW_API = "/RCD_WebApi/ProgramPipeline";
export const SAP_PO_SHIPMENT_NEW_API = "/RCD_WebApi/SAPProgramPipeline";
export const PO_SHIPMENT_API = "/SS_WebApi/PoRiRcByDP";
export const MANUFACTURER_API = "/RCD_WebApi/ItemByManufacturer";
export const MANUFACTURER_LLIN_API = "/SS_WebApi/PoRiRcByDP";
export const SUPPLIER_API = "/RCD_WebApi/ItemBySupplier";
export const COUNTRY_API = "/RCD_WebApi/ItemCountry";
export const RECEIVED_API = "/MainDashboard_WebApi/ReceiveTrend";
export const COUNTRY_ITEM_API = "/RCD_WebApi/CountryItem";
export const PROGRAM_MANUFACTURER_API = "/RCD_WebApi/ProgramManufacturer";
export const ALTERNATE_ITEM_API = "/IUBVIUI_WebApi/ItemAlternateListByProgram";
export const STAGING_ISSUE_API = "/StagingReports/StagingIssueReport";
export const STAGING_SOH_API = "/StagingReports/StagingSOHReport";
export const ITEMS_SOH_API = "/SS_WebApi/SOHMOSAMC_ByEnvronment";
export const ISSUED_API = "/OIH_WebApi/ItemDistributionHub2Facility";
export const NG_SUPPLIERS_API = "/eRIS_WebApi/FamilyPlanningAgentSummary";
export const ITEM_COST_HISTORY_API = "/RCD_WebApi/ItemPriceAnalysis";
export const ITEM_PRICE_HISTORY_API = "/RCD_WebApi/ItemPriceHistory";
export const RECENT_ISSUES_API = "/OID_WebApi/EnvironmentRecentIssued";
export const RECENT_RECEIVES_API = "/POD_WebApi/EnvironmentRecentReceipt";
export const PENDING_ORDER_API = "/RQD_WebApi/OpenOrders";
export const STOCK_STATUS_LOCATION_API = "/SS_WebApi/StockStatusByEnvironment";
export const STOCK_STATUS_BY_HUB_API = "/SS_WebApi/SOHMOSAMC_ByEnvronment";
export const STOCK_STATUS_BY_QUANTITIES_API = "/OID_WebApi/QuantityIssuedByIUF";
export const STOCK_STATUS_BY_EXPIRY_API = "/SS_WebApi/ExpiryDateSOH";
export const GIT_BY_INVOICE_API = "/RIH_WebApi/EnvironmentGITHeader";
export const INVOICE_API = "/OIH_WebApi/EnvironmentIssueByDocumentType";
export const PENDING_DETAIL_API = "/RQD_WebApi/OpenOrdersDetial";
export const GIT_DETAIL_API = "/RIH_WebApi/EnvironmentGITByItem";
export const INVOICE_DETAIL_API = "/OIH_WebApi/EnvironmentIssueByDocumentTypeDetail";
export const FACILITY_COUNT_API = "/OIH_WebApi/DistributionByFacilityType";
export const HUB_TO_CENTER_API = "/OIH_WebApi/ItemDistributionHubToFacilityByProgram";
export const CENTER_TO_HUB_API = "/OIH_WebApi/ItemDistributionCenterToHubByProgram";
export const SHI_ITEM_API = "/SS_WebApi/IUEBBBAMCMOS";
export const DISTRIBUTION_API = "/OIH_WebApi/DPlanVsIssued";
export const MISCELLANEOUS_STOCK_API = "/RRFSSNDPPLN_WebApi/MOHRRFStockStatusWithPipelineByMode";
export const MISCELLANEOUS_STOCK_API_SAP = "/RRFSSNDPPLN_WebApi/StockStatusWithPipelineByMode_SAP";
export const MISCELLANEOUS_STOCK_DETAIL_API = "/RRFSSNDPPLN_WebApi/MOHRRFStockStatusWithPipelineByActivity";
export const PURCHASE_ORDER_API = "/PODRIDRQDPKOID_WebApi/ProcurementLifeCycleTrace";
export const SHIPMENT_DWELLING_API = "/RRFSSNDPPLN_WebApi/ShipmentDwellTimeReport";
export const LC_CAD_API = "/RRFSSNDPPLN_WebApi/LCCADExpriyReport";
export const NATIONAL_AMC_API = "/RRFSSNDPPLN_WebApi/GetItemAMC";
export const QR_DISTRIBUTION_BY_COMMODITY_API = "/PFSAQuarterReport/PFSADistributionByCommodityTypeStaging";
export const QR_DISTRIBUTION_BY_OWNERSHIP_API = "/PFSAQuarterReport/PFSADistributionByOwnerShipTypeStaging";
export const QR_PROCURED_LOCAL_API = "/PFSAQuarterReport/PFSAProcuredByLocalManufacturer";
export const QR_RAW_DISTRIBUTION_API = "/PFSAQuarterReport/PFSADistributionRawDataStaging?EnvironmentCode";
export const QR_DISTRIBUTION_BY_PROGRAM_APi = "/PFSAQuarterReport/PFSADistributionByProgramRawDataStaging?EnvironmentCode";
export const QR_SOH_API = "/PFSAQuarterReport/PFSASOHRawDataStaging?EnvironmentCode";
export const QR_LOCAL_API = "/PFSAQuarterReport/PFSALocalManufacturerData?EnvironmentCode";
export const QR_NEAR_EXPIRY_API = "/PFSAQuarterReport/PFSANearExpiryRawData?EnvironmentCode";
export const QR_EXPIRED_API = "/PFSAQuarterReport/PFSAExpiryRawData?EnvironmentCode";
export const ITEM_DISTRIBUTION_API = "/StagingReports/MinimalStagingIssueReport";
export const FACILITY_SYNC_API = "/Dashboard/GetFacilitySyncStatus";
export const FACILITY_STOCK_STATUS_API = "/StockStatus/GetStockStatus";
export const HUB_SOH_API = "/PSM_WebApi/HubSOHAMCByProgram";
export const ITEM_EXPIRY_API = "/PSM_WebApi/ItemExpiryDateByProgram";
export const LOCAL_PO_API = "/Public/PurchaseOrderReport?From&To";
export const LOCAL_PO_RECEIVES_API = "/StagingReports/LocalPOReceives";
export const PSM_CENTER_TO_HUB_API = "/PSM_WebApi/CenterToHubIssue";
export const PSM_HUB_TO_FACILITY_API = "/PSM_WebApi/HubToFacilitiesIssue";
export const SHIPMENT_STATUS_API = "/PODRIDRQDPKOID_WebApi/CenterInvoiceDistribution";
export const PO_DETAIL_API = "/PODRIDRQDPKOID_WebApi/CenterPODetail";
export const DISTRIBUTION_PLAN_API = "/StagingReports/GF_CovidEquipmentDistrbutionSummary";
export const BENEFICIARY_BY_AGE_API = "/AEFI_WebApi/BeneficiariesByAge";
export const BENEFICIARY_API = "/AEFI_WebApi/ListOfBeneficiaries";
export const FACILITY_CERTIFICATION_API = "/eRIS_WebApi/FacilitiesWithCertificates";
export const PERMITTED_PRODUCTS_API = "/eRIS_WebApi/IpPipItemsList";
export const APPLICATIONS_RELEASED_API = "/eRIS_WebApi/ApplicationReleasedOnPort";
export const REGISTERED_PRODUCTS_API = "/eRIS_WebApi/RegisteredProducts?Page=1&PageSize=10&From=2022-06-09&To=2023-06-09";
export const USER_MANAGEMENT_API = "/UA_WebApi/UserList";
export const GROUP_MANAGEMENT_API = "/Permission/adminGetAllGroups";
export const PAGE_MANAGEMENT_API = "/Permission/adminGetAllPages";
export const GROUP_PAGES_MANAGEMENT_API = "/Permission/adminGetAllGroupPages";
export const GROUP_PROGRAMS_MANAGEMENT_API = "/Permission/adminGetAllProgramGroup";
export const BIN_CARD_API = "/OIDRCD_WebApi/ByDateIU_MostRecentIssueReceive";
export const ITEM_UNIT_COST_API = "/PriceAnalysis/ItemUnitCostAnalysis";
export const ORDER_FREQUENCY_API = "/PriceAnalysis/OrderFrequencyAnalysis";
export const SHI_PRODUCT_API = "/SS_WebApi/SOHByExpiryDate";
export const POTENTIAL_EXPIRIES = "/StagingReports/PotentialExpiries";
export const OVERAGE_REPORT = "/StagingReports/OverageReport";

export const ERP_Distribution_API = "/ERPReports/ERPIssuesReport";
export const ERP_Receive_API = "/ERPReports/ERPReceivesReport";
export const ERP_UsableStock_API = "/ERPReports/ERPSOHReport?IsAggregate=True";
export const ERP_UsableStock_DETAIL_API = "/ERPReports/ERPSOHReport?IsAggregate=False";

const Apis = [STOCK_ON_HAND_API];

export default Apis;
