import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../shared/NavBar";
import SideNav from "../../shared/SideNav";
import styled from "styled-components";

import {
  getNearExpiryBreakdown,
  getStockUtilization,
  getDistributionByFacilityType,
  getDistributionByOwnershipType,
  ItemProcurer,
  getOrderFillRate,
  getSupplierFillRate,
  ItemFunding,
  getMOSShareBySite,
  getCenterPipeline,
  getHypotheticalMOSShareBySite,
  CashVsCredit,
  getFillRateGauge,
  getDistributionByFacilityTypePerItem,
  getItemProcurerPerItem
} from "../../../Services/chartRequests";
import {
  getHeaderInformation,
  getProgramItemsNation,
  getShiHeader,
} from "../../../Services/ApiManager";
import {
  STOCK_ON_HAND_API,
  STOCK_STATUS_API,
  MANUFACTURER_API,
  SUPPLIER_API,
  COUNTRY_API,
  CENTER_TO_HUB_API,
  FACILITY_COUNT_API,
  HUB_TO_CENTER_API,
  SHI_ITEM_API,
  ITEMS_SOH_API,
  MANUFACTURER_LLIN_API,
  DISTRIBUTION_API,
  PROGRAM_MANUFACTURER_API,
  RECEIVED_API,
  ISSUED_API,
  PO_SHIPMENT_NEW_API,
  SAP_PO_SHIPMENT_NEW_API,
  NG_SUPPLIERS_API,
} from "../../shared/Shared Utils/AppData.js";

import CustomPieChart from "../../shared/Charts/CustomPieChart";
import CustomBarChartV2 from "../../shared/Charts/CustomBarChartV2";
import GroupedStackedBarChart from "../../shared/Charts/GroupedStackedBarChart";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { getEnvironemntList } from "../../../Services/ApiManager";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  STOCK_STATUS_COLUMN,
  MANUFACTURER_COLUMN,
  SUPPLIER_COLUMN,
  COUNTRY_COLUMN,
  STOCK_STATUS_BLOOD_BANK_COLUMN,
  SHI_ITEM_COLUMN,
  ISSUED_COLUMN,
  STOCK_STATUS_LLIN_COLUMN,
  MANUFACTURER_LLIN_COLUMN,
  DISTRIBUTION_COLUMN,
  RECEIVED_COLUMN,
  ITEM_DISTRIBUTION_COLUMN,
  PO_SHIMPENT_COLUMN,
  SAP_PO_SHIMPENT_COLUMN,
  NG_SUPPLIERS_COLUMN,
  STOCK_STATUS_COLUMN_EXPAND,
  GetStockStatusColumnExpand
} from "./ProgramsTableColumns";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import CustomCard from "../../shared/Shared Utils/CustomCards";
import CustomCardV2 from "../../shared/Shared Utils/CustomCardV2";

import {
  STOCK_ON_HAND_DEFAULT_PARAM,
  STOCK_STATUS_DEFAULT_PARAM,
  PROGRAM_PARAM,
  MANUFACTURER_PARAM,
  CENTER_TO_HUB_DEFAULT_PARAM,
  SHI_ITEM_DEFAULT_PARAM,
  STOCK_ON_HAND_ITEM__PARAM,
  MANUFACTURER_LLIN_PARAM,
  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
  ENVIRONMENT_GROUP_CODE,
  ENVIRONMENT_SELECT_CONFIG,
  RECEIVED_PARAM,
  MODE_CODE_DEFAULT_PARAM,
  MODE_CODE_DEFAULT_PARAM_PAGE_SIZE,
  ACCOUNT_SELECT_CONFIG,
  YEAR_SELECT_CONFIG,
} from "../../shared/Shared Utils/DataTableConstants";
import {
  MdMedicalServices,
} from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { GoPackageDependents } from "react-icons/go";
import { HUB } from "../../shared/Shared Utils/InputConstants";
import {parseGridColumns, parseGridRows } from "../../../Utils/ChartConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import sohPic from "../../../assets/cardIcons/soh.svg";
import stackPic from "../../../assets/cardIcons/stack.svg";
import gitPic from "../../../assets/cardIcons/git.svg";
import expPic from "../../../assets/cardIcons/expired.svg";
import qexpPic from "../../../assets/cardIcons/qExpired.svg";
import dmgPic from "../../../assets/cardIcons/damaged.svg";
import gapPic from "../../../assets/cardIcons/gap.svg";
import ovrgPic from "../../../assets/cardIcons/overage.svg";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { facillityCountColumn } from "../location/LocationsTableColumns";
import RDFSideNav from "./RDFSideNav";
import moment from "moment";
import Maps from "./Maps";
import { ManufacturerItemsModal } from "./ManufacturerItems";
import { useFanosStore } from "../../shared/Shared Utils/store";
import { CountryItemsModal } from "./CountryItems";
import { FormatDecimal, getPercentageValues } from "../../../Utils/NumberUtils";
import { useQuery } from "@tanstack/react-query";
import { toEthiopian } from "ethiopian-date";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";
import { ShiProductModal } from "./ShiProductModal";
import { StockItemsModal } from "./StockItems";
import LlinSideNav from "./llinSideNav";
import { StockQuantityModal } from "./StockQuantity.js";
import { StockExpiryModal } from "./StockExpiry.js";
import { IssueTrendModal } from "../../Items/IssueTrend.js";
import { GetNormalizeLabels } from "../../../Utils/ArrayUtils.js";

export default function Programs() {
  const history = useNavigate();
  const location = useLocation();
  let { ProgramName } = useParams();
  const { current: myArray } = useRef(["one", "two", "three"]);
  const alternateUI = ["AC", "CD", "CC", "Hema", "Labs", "Viral"];
  const defaultRDF = ["AC"];
  const bloodBankPrograms = ["BBS", "BBE"];
  const shi = ["SHI"];
  const llin = ["LLIN"];
  const programCode = location.state?.ProgramCode || pathname.split('/')[2].split('-')[1];
  const headerName = location.state?.ProgramName || pathname.split('/')[2].split('-')[0];
  const [modeCode, setModeCode] = useState(defaultRDF.includes(programCode)?"RDF": "HPR");
  const [environment, setEnvironment] = useState("HUB");
  const [stockUtilizationParams, setStockUtilizationParams] = useState([]);
  const {data: { data: { Data: stockUtilization }} = { data: { Data: [] }}, isFetching: stockUtilizationLoading} = useQuery({
    queryKey: ['stockUtilization', modeCode, programCode, stockUtilizationParams],
    queryFn: () => getStockUtilization(modeCode, programCode, "ProductCN", stockUtilizationParams),
    staleTime: 10800000,
  });
  const [issueHistory, setIssueHistory] = useState([]);
  const [distByTypeParams, setDistByTypeParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  distByType }} = { data: { Data: [] }}, isFetching: distByTypeLoading} = useQuery({
    queryKey: ['distByType', modeCode, programCode, distByTypeParams],
    queryFn: () => getDistributionByFacilityType(modeCode, programCode, distByTypeParams),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  [headerInfo] }} = { data: { Data: [] }}, isFetching: headerInfoLoading} = useQuery({
    queryKey: ['headerInfo', modeCode, programCode],
    queryFn: () => getHeaderInformation(modeCode, programCode),
    staleTime: 10800000,
  });
  const [expiryStatus, setExpiryStatus] = useState([]);
  const [distByOwnershipParams, setDistByOwnershipParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  distByOwnership }} = { data: { Data: [] }}, isFetching: distByOwnershipLoading} = useQuery({
    queryKey: ['distByOwnership', modeCode, programCode, distByOwnershipParams],
    queryFn: () => getDistributionByOwnershipType(modeCode, programCode, distByOwnershipParams),
    staleTime: 10800000,
  });
  const [itemProcurementParams, setItemProcurementParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  itemProcurement }} = { data: { Data: [] }}, isFetching: itemProcurementLoading} = useQuery({
    queryKey: ['itemProcurement', modeCode, programCode, itemProcurementParams],
    queryFn: () => ItemProcurer(modeCode, programCode, itemProcurementParams),
    staleTime: 10800000,
  });
  const [orderFillRateParams, setOrderFillRateParams] = useState([]);
  const {data: { data: { Data:  orderFillRate }} = { data: { Data: [] }}, isFetching: orderFillRateLoading} = useQuery({
    queryKey: ['orderFillRate', modeCode, programCode, orderFillRateParams],
    queryFn: () => getOrderFillRate(modeCode, programCode, orderFillRateParams),
    staleTime: 10800000,
  });
  const [supplierFillRateParams, setSupplierFillRateParams] = useState([]);
  const {data: { data: { Data:  supplierFillRate }} = { data: { Data: [] }}, isFetching: supplierFillRateLoading} = useQuery({
    queryKey: ['supplierFillRate', modeCode, programCode, supplierFillRateParams],
    queryFn: () => getSupplierFillRate(modeCode, programCode, supplierFillRateParams),
    staleTime: 10800000,
  });
  const [itemFundingParams, setItemFundingParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  itemFunding }} = { data: { Data: [] }}, isFetching: itemFundingLoading} = useQuery({
    queryKey: ['itemFunding', modeCode, programCode, itemFundingParams],
    queryFn: () => ItemFunding(modeCode, programCode, itemFundingParams),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  shareBySite }} = { data: { Data: [] }}, isFetching: shareBySiteLoading} = useQuery({
    queryKey: ['shareBySite', modeCode, programCode, environment],
    queryFn: () => getMOSShareBySite(modeCode, programCode, environment),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  idealShareBySite }} = { data: { Data: [] }}, isFetching: idealShareBySiteLoading} = useQuery({
    queryKey: ['idealShareBySite', modeCode, programCode, environment],
    queryFn: () => getHypotheticalMOSShareBySite(modeCode, programCode, environment),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  centerPipeline }} = { data: { Data: [] }}, isFetching: centerPipelineLoading} = useQuery({
    queryKey: ['centerPipeline', modeCode, programCode],
    queryFn: () => getCenterPipeline(modeCode, programCode),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  distributionByFacilityTypePerItem }} = { data: { Data: [] }}, isFetching: distributionByFacilityTypePerItemLoading} = useQuery({
    queryKey: ['distributionByFacilityTypePerItem', modeCode, programCode],
    queryFn: () => getDistributionByFacilityTypePerItem(modeCode, programCode),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  itemProcurerPerItem }} = { data: { Data: [] }}, isFetching: itemProcurerPerItemLoading} = useQuery({
    queryKey: ['itemProcurerPerItem', modeCode, programCode],
    queryFn: () => getItemProcurerPerItem(modeCode, programCode),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  itemProgram }} = { data: { Data: [] }}, isFetching: itemProgramLoading} = useQuery({
    queryKey: ['itemProgram', modeCode, programCode],
    queryFn: () => getProgramItemsNation(modeCode, programCode, "ProductCN"),
    staleTime: 10800000,
  });
  
  const manufacturerItemsModalOpen = useFanosStore((state) => state.manufacturerItemsModalOpen);
  const modalManufacturer = useFanosStore((state) => state.modalManufacturer);
  const setManufacturerItemsModalOpen = useFanosStore((state) => state.setManufacturerItemsModalOpen);
  const countryItemsModalOpen = useFanosStore((state) => state.countryItemsModalOpen);
  const modalCountry = useFanosStore((state) => state.modalCountry);
  const setCountryItemsModalOpen = useFanosStore((state) => state.setCountryItemsModalOpen);
  const stockItemModalOpen = useFanosStore((state) => state.stockItemModalOpen);
  const modalItem = useFanosStore((state) => state.modalItem);
  const setStockItemModalOpen = useFanosStore((state) => state.setStockItemModalOpen);

  const issueTrendModalOpen = useFanosStore((state) => state.issueTrendModalOpen);
  const setIssueTrendModalOpen = useFanosStore((state) => state.setIssueTrendModalOpen);
  const issueTrendModalData = useFanosStore((state) => state.issueTrendModalData);
  const setIssueTrendModalData = useFanosStore((state) => state.setIssueTrendModalData);

  const pathname = window.location.pathname;

  const itemProcurementFields = ["EPSS", "IDAFoundation", "UNFPA", "USAID", "UNICEF"];
  const itemProcurementLabels = ["EPSS", "IDAFoundation", "UNFPA", "USAID", "UNICEF"];

  const defaultBackgroundColor = 
      [
        "#8ed0d1",
        "#67a789",
        "#15616d",
        "#C4B8A6",
        "#ffddad",
        "#e06c00",
        "#73605b",
        "#e4ea8c",
        "#4d648d"
      ]

  useState(shareBySite);
  const pageFilterParams = {};
  const filterParams = {};
  const handle_change = (param) => {
    pageFilterParams[param.paramName] = param.paramValue;
  };
  const select = [HUB];

  
  const [yearFilter, setYearFilter] = useState(2016);
  const [checked, setChecked] = useState(defaultRDF.includes(programCode));

  const [showMoreCharts, setShowMoreCharts] = useState(false);

  const [currentTab, setCurrentTab] = useState("ERP");
  const [tabs, setTabs] = useState(["Vitas", "ERP"]);

  function changeTab(tab) {
    setCurrentTab(tab);
  }

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDataFilter) {
      let newDate = new Date();
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let currentDate = `${month}/${day}/${year}`;
      filterParams["TransactionDate"] = currentDate;
    }
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = dateFilterFlag.startDate || moment().subtract(1, 'months').format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    
    filterParams["ProgramCode"] = programCode;
    return filterParams;
  }


  if(llin.includes(programCode)){
    return (<Llin headerName={headerName} programCode={programCode} loadFilterToDefaultParams={loadFilterToDefaultParams} modeCode={modeCode} />)
  }

  if(shi.includes(programCode)){
    return (<Shi headerName={headerName} programCode={programCode} loadFilterToDefaultParams={loadFilterToDefaultParams} modeCode={modeCode} setModeCode={setModeCode} />)
  }

  if(bloodBankPrograms.includes(programCode)){
    return (<BloodBankPrograms headerName={headerName} loadFilterToDefaultParams={loadFilterToDefaultParams} modeCode={modeCode} />)
  }


  if(alternateUI.includes(programCode) || ((!alternateUI.includes(programCode) && !bloodBankPrograms.includes(programCode) && !shi.includes(programCode) && !llin.includes(programCode)) && pathname.split('/')[1] == 'A_Programs')){
    return (<AlternateUI headerName={headerName} programCode={programCode} loadFilterToDefaultParams={loadFilterToDefaultParams} modeCode={modeCode} setModeCode={setModeCode} />)
  }
  
  
  return (
    <Wrapper>
      <NavBar title={`Fanos ${headerName} Program Dashboard`} />
      {/* <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: "10",
          textAlign: "center",
          padding: "1rem",
          color: "white",
          display: "flex",
          flexDirection: "row",
          background: "#15616d",
          boxShadow: "0 2px 5px 0 grey",
        }}
      >
        <Button
          style={{ color: "white" }}
          onClick={() => {
            history("/");
          }}
        >
          Home
        </Button>
        <div
          style={{
            textAlign: "center",
            marginLeft: " 20rem",
          }}
        >
          <h4>Fanos {headerName} Program Dashboard</h4>
        </div>
      </div> */}
      <div className="container-box">
        {" "}
        
        <div className="container-context">
          {" "}
          <SideNav data={itemProgram} header={headerName} />
          <GridContainer>
            <TableWrapper>
              <div className="flex-row" style={{width: "100%"}}>
                <CustomCardV2
                  widget_config={{
                    title: "SOH",
                    value: headerInfo ? headerInfo.SOHValueInUSD.toLocaleString() : 0,
                    logo_url: sohPic,
                    currency: "$ "
                  }}
                  Icon={MdMedicalServices}
                />
                <CustomCardV2
                  widget_config={{
                    title: "Issued",
                    value: headerInfo ? headerInfo.IssuedValueInUSD.toLocaleString() : 0,
                    currency: "$ "
                  }}
                  Icon={TbTruckDelivery}
                />
                <CustomCardV2
                  widget_config={{
                    title: "Planned",
                    value: headerInfo
                      ? headerInfo.UnderOrderValueInUSD.toLocaleString()
                      : 0,
                    logo_url: stackPic,
                    currency: "$ "
                  }}
                />
                <CustomCardV2
                  widget_config={{
                    title: "GIT",
                    value: headerInfo
                      ? headerInfo.GValueInUSD.toLocaleString()
                      : 0,
                    logo_url: gitPic,
                    currency: "$ "
                  }}
                />
                <CustomCardV2
                  widget_config={{
                    title: "Expired",
                    value: headerInfo
                      ? headerInfo.EValueInUSD.toLocaleString()
                      : 0,
                    logo_url: expPic,
                    currency: "$ "
                  }}
                />
                <CustomCardV2
                  widget_config={{
                    title: "nExpiry",
                    value: headerInfo
                      ? headerInfo.L3ValueInUSD.toLocaleString()
                      : 0,
                    logo_url: qexpPic,
                    currency: "$ "
                  }}
                />
              {/* <CustomCard
                widget_config={{
                  title: "Damaged",
                  value: headerInfo
                    ? headerInfo.QuantityDamaged.toLocaleString()
                    : 0,
                  logo_url: dmgPic,
                }}
              /> */}
              {/* <CustomCard
                widget_config={{
                  title: "Gap",
                  value: headerInfo ? headerInfo.Gap.toLocaleString() : 0,
                  logo_url: gapPic,
                }}
              /> */}
              {/* <CustomCard
                widget_config={{
                  title: "Overage",
                  value: headerInfo
                    ? headerInfo.Overage.toLocaleString()
                    : 0,
                  logo_url: ovrgPic,
                }}
              /> */}
              {/* <div className="button-container">
                {" "}
                <button
                  className="button"
                  onClick={() => {
                    history("/AlternateItemList", {
                      state: {programCode},
                    });
                  }}
                >
                  Item List
                </button>
                <button
                  className="button"
                  onClick={() => {
                    history("/StagingData", {
                      state: location.state,
                    });
                  }}
                >
                  Staging data
                </button>
              </div> */}
              </div>
            </TableWrapper>
            
            <TableWrapper>
                <CustomDataGrid
                  component_config={{
                    title: "Stock Status: National",
                    data_source_api: STOCK_STATUS_API,
                    data_property: "Data",
                    columns: GetStockStatusColumnExpand(programCode),
                    isSOH: false,
                    wgInfoId: 'wgSohAmcMosByItem',
                    use_default_filter_params: false,
                    defaultFilterParams: loadFilterToDefaultParams(
                      STOCK_STATUS_DEFAULT_PARAM,
                      { hasDataFilter: false }
                    ),
                  }}
                />
              </TableWrapper>           

            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Stock on Hand: Regional Hubs Breakdown",
                  data_source_api: STOCK_ON_HAND_API,
                  data_property: "Data",
                  isSOH: true,
                  wgInfoId: 'wg-soh-by-site',
                  use_default_filter_params: false,
                  select_filter_config: [ENVIRONMENT_SELECT_CONFIG, ENVIRONMENT_GROUP_CODE],
                  defaultFilterParams: loadFilterToDefaultParams(
                    STOCK_ON_HAND_DEFAULT_PARAM,
                    { hasDataFilter: true }
                  ),
                  date_picker: {
                    date_range: false,
                    start_date_param_name: "TransactionDate",
                    start_date_param: "TransactionDate",
                  },
                }}
              />
            </TableWrapper>
              
              <LongChartWrapper>
                <CustomBarChartV2
                  datas={[getPercentageValues(GetNormalizeLabels(itemProcurerPerItem, itemProcurementFields, itemProcurementLabels), itemProcurerPerItem, 1)]}
                  labels={[GetNormalizeLabels(itemProcurerPerItem, itemProcurementFields, itemProcurementLabels)]}
                  fields={[
                    GetNormalizeLabels(itemProcurerPerItem, itemProcurementFields, itemProcurementLabels),
                  ]}
                  styles={[
                    {
                      padding: "1rem",
                      position: "relative",
                      height: "20.2rem",
                      background: "white",
                    },
                  ]}
                  chart_options={{
                    title: "Item Procurement By Agent",
                    showExpandAlt: true,
                    showAmount: true,
                    yTitle: "Count",
                    tooltips: ["FullItemName"],
                    customTooltips: GetNormalizeLabels(itemProcurerPerItem, itemProcurementFields, itemProcurementLabels),
                    option: "ProductCN",
                    parseForGrid: true,
                    wgInfoId: "wgPipeline",
                    max: 100,
                    background: defaultBackgroundColor,
                    removeEmptyLabels: true
                  }}
                  chartTypes={["bar-chart"]}
                />
              </LongChartWrapper>

              <StandardChartWrapper>
                <ChartWrapper title="Procurement Agents" wgInfoId="wgProcurerAgents" parameter={itemProcurementParams} setParameter={setItemProcurementParams} isLoading={itemProcurementLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                  <CustomPieChart
                    data={itemProcurement}
                    label={"Procurement Agent"}
                    fields={["AmountInBirr", "ProcurmentAgent"]}
                    title="Procurement Agents"
                    style={{
                      position: "relative",
                      width: "20rem",
                      background: "White",
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                    component_config= {{ background_color: defaultBackgroundColor
                    }}
                  />
                  <DataGrid
                    style={{
                      padding: "1rem",
                    }}
                    initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                    autoHeight
                    rows={parseGridRows(itemProcurement, [ "ProcurmentAgent", "AmountInBirr"])}
                    columns={parseGridColumns(["ProcurmentAgent", "AmountInBirr"])}
                    getRowId={(row) => row.ProcurmentAgent}
                    rowHeight={30}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    pagination
                    animateRows={true}
                    disableColumnSelector
                    components={{ Toolbar: GridToolbar }}
                  />
                  <CustomPieChart
                    data={itemProcurement}
                    label={"Procurement Agent"}
                    fields={["AmountInBirr", "ProcurmentAgent"]}
                    title="Procurement Agents"
                    style={{
                      height:'15rem',
                      width: '32rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                  />
                </ChartWrapper>
              </StandardChartWrapper>
              
              <TableWrapper>
                
                <div className="flex-row flex-1" style={{justifyContent: 'end', marginBottom: '-1.5rem'}}>
                  <ul className="flex-row tab-ul-admin">
                    { tabs.map(function (tab) {
                        return (<li className={`tab-li ${ currentTab === tab 
                            ? 'tab-li-active' : null }`}>
                          <a onClick={() => changeTab(tab)}>{tab}</a>
                        </li>
                        );
                    })}
                  </ul>
                </div>
              </TableWrapper>
              { currentTab == "ERP" && 
              <TableWrapper>
                  <CustomDataGrid
                    component_config={{
                      title: "Purchase Order/Incoming Shipments ERP",
                      data_source_api: SAP_PO_SHIPMENT_NEW_API,
                      data_property: "Data",
                      columns: SAP_PO_SHIMPENT_COLUMN,
                      isSOH: false,
                      use_default_filter_params: false,
                      defaultFilterParams: loadFilterToDefaultParams(
                        {...MODE_CODE_DEFAULT_PARAM, ProgramCode: programCode},
                        { hasDateFilter: false }
                      ),
                    }}
                  />
              </TableWrapper> 
              }

              { currentTab == "Vitas" && 
              <TableWrapper>
                  <CustomDataGrid
                    component_config={{
                      title: "Purchase Order/Incoming Shipments",
                      data_source_api: PO_SHIPMENT_NEW_API,
                      data_property: "Data",
                      columns: PO_SHIMPENT_COLUMN,
                      isSOH: false,
                      use_default_filter_params: false,
                      defaultFilterParams: loadFilterToDefaultParams(
                        {...MODE_CODE_DEFAULT_PARAM, ProgramCode: programCode},
                        { hasDateFilter: false }
                      ),
                    }}
                  />
              </TableWrapper> 
              }

              <TableWrapper>
                <CustomDataGrid
                  component_config={{
                    title: "Recent Receives",
                    data_source_api: RECEIVED_API,
                    data_property: "Data",
                    columns: RECEIVED_COLUMN,
                    notes: "*showing 24 months of data",
                    isSOH: false,
                    use_default_filter_params: false,
                    defaultFilterParams: loadFilterToDefaultParams(
                      {...RECEIVED_PARAM, ProgramCode: programCode},
                      { hasDateFilter: false }
                    ),
                  }}
                />
              </TableWrapper>

              <TableWrapper>
                <Maps param={{ProgramCode: programCode}} modeCodeParam={{ModeCode: modeCode}} page={"Program"} hide_header={true}/>
              </TableWrapper>          
              
              <LongChartWrapper>
                <CustomBarChartV2
                  datas={[getPercentageValues(["HealthCenter", "Hospital", "Others", "Woreda"], distributionByFacilityTypePerItem)]}
                  labels={[["Health Center", "Hospital", "Others", "Woreda"]]}
                  fields={[
                    [
                      "HealthCenter",
                      "Hospital",
                      "Others",
                      "Woreda"
                    ],
                  ]}
                  styles={[
                    {
                      padding: "1rem",
                      position: "relative",
                      height: "20.2rem",
                      background: "white",
                    },
                  ]}
                  chart_options={{
                    title: "Item Distribution By Facility Type",
                    showExpandAlt: true,
                    showAmount: true,
                    yTitle: "Percent",
                    tooltips: ["FullItemName"],
                    customTooltips: ["HealthCenter", "Hospital", "Others", "Woreda"],
                    option: "ProductCN",
                    parseForGrid: true,
                    wgInfoId: "wgPipeline",
                    max: 100,
                    background: defaultBackgroundColor
                  }}
                  chartTypes={["bar-chart"]}
                />
              </LongChartWrapper>
              <StandardChartWrapper>
                <ChartWrapper title="Distribution by Facility Type" wgInfoId="wgDistrByFacilitytypeMt" parameter={distByTypeParams} setParameter={setDistByTypeParams} isLoading={distByTypeLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                  <CustomPieChart
                    data={distByType}
                    label={"Distribution by facility type"}
                    fields={["AmountIssuedInBirr", "InstitutionType"]}
                    title="Distribution by Facility Type"
                    style={{
                      position: "relative",
                      width: "20rem",
                      background: "White",
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                    component_config= {{ background_color: defaultBackgroundColor }}
                  />
                  <DataGrid
                    style={{
                      padding: "1rem",
                    }}
                    initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                    autoHeight
                    rows={parseGridRows(distByType, ["InstitutionType", "AmountIssuedInBirr"])}
                    columns={parseGridColumns(["InstitutionType", "AmountIssuedInBirr"])}
                    getRowId={(row) => row.InstitutionType}
                    rowHeight={30}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    pagination
                    animateRows={true}
                    disableColumnSelector
                    components={{ Toolbar: GridToolbar }}
                  />
                  <CustomPieChart
                    data={distByType}
                    label={"Distribution by facility type"}
                    fields={["AmountIssuedInBirr", "InstitutionType"]}
                    title="Distribution by Facility Type"
                    style={{
                      height:'15rem',
                      width: '32rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                  />
                </ChartWrapper>
              </StandardChartWrapper>

              <TableWrapper>
                <CustomDataGrid
                  component_config={{
                    title: "Issue Data: Facility",
                    data_source_api: ISSUED_API,
                    data_property: "Data",
                    columns: ITEM_DISTRIBUTION_COLUMN,
                    isSOH: false,
                    use_default_filter_params: false,
                    defaultFilterParams: loadFilterToDefaultParams(
                      {
                        ...MODE_CODE_DEFAULT_PARAM_PAGE_SIZE,
                        ProgramCode: programCode
                      },
                      {
                        hasDateRangeFilter: true,
                        startDate: moment().subtract(1, 'weeks').format("YYYY-MM-DD")
                      }
                    ),
                    date_picker: {
                      date_range: true,
                      start_date_param_name: "From",
                      end_date_param_name: "To",
                      date_field: "IssueDate",
                      weekly_report: true,
                    },
                  }}
                />
              </TableWrapper>

            <TableWrapperAlternate>
              <Maps param={{ProgramCode: programCode}} modeCodeParam={{ModeCode: modeCode}} page={"Program"} map_type={"FacilityCircleMap"}/>
            </TableWrapperAlternate>
              
              
              <TableWrapper>
                <button
                    className="rs-button rs-button-summit"
                    type="button"
                    onClick={ function () {
                      setShowMoreCharts(!showMoreCharts);
                    }}
                  >   
                  <div className="flex-row flex-center">
                    <div> { showMoreCharts ? "Show less" : "Load more" } </div>
                  </div>
                </button>
              </TableWrapper>

              { (showMoreCharts && 
                <>
                <LongChartWrapper>
                  <CustomBarChartV2
                    datas={[getPercentageValues(["PurchaseOrdered", "InvoicedM", "BelowMax", "AboveMax", "ProjectedDaysOutOfStock",], centerPipeline)]}
                    labels={[["PO", "GIT", "BelowMax", "AboveMax", "pDOS"]]}
                    fields={[
                      [
                        "PurchaseOrderedPercentage",
                        "InvoicedMPercentage",
                        "BelowMaxPercentage",
                        "AboveMaxPercentage",
                        "ProjectedDaysOutOfStockPercentage",
                      ],
                    ]}
                    styles={[
                      {
                        padding: "1rem",
                        position: "relative",
                        height: "20.2rem",
                        background: "white",
                      },
                    ]}
                    chart_options={{
                      title: "Pipeline: Incoming Shipment",
                      showExpandAlt: true,
                      showAmount: true,
                      yTitle: "Quantity",
                      tooltips: ["FullItemName"],
                      customTooltips: ["PurchaseOrdered", "InvoicedM", "BelowMax", "AboveMax", "ProjectedDaysOutOfStock",],
                      option: "ProductCN",
                      parseForGrid: true,
                      wgInfoId: "wgPipeline",
                      max: 100,
                    }}
                    chartTypes={["bar-chart"]}
                  />
                </LongChartWrapper>

                <StandardChartWrapper>
                  <ChartWrapper title="Distribution By Ownership Type" wgInfoId="wgDistrByOwnershiptype" parameter={distByOwnershipParams} setParameter={setDistByOwnershipParams} isLoading={distByOwnershipLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                    <CustomPieChart
                      data={distByOwnership}
                      label={"Distribution By Ownership Type"}
                      fields={["AmountIssuedInBirr", "OwnershipType"]}
                      title="Distribution by Ownership Type"
                      style={{
                        position: "relative",
                        width: "20rem",
                        background: "White",
                        padding: "0rem 1rem 1rem 1rem",
                      }}
                    />
                    <DataGrid
                      style={{
                        padding: "1rem",
                      }}
                      initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                      autoHeight
                      rows={parseGridRows(distByOwnership, ["OwnershipType", "AmountIssuedInBirr",])}
                      columns={parseGridColumns(["OwnershipType", "AmountIssuedInBirr",] )}
                      getRowId={(row) => row.OwnershipType}
                      rowHeight={30}
                      pageSize={10}
                      rowsPerPageOptions={[5, 10, 25]}
                      pagination
                      animateRows={true}
                      disableColumnSelector
                      components={{ Toolbar: GridToolbar }}
                    />
                    <CustomPieChart
                      data={distByOwnership}
                      label={"Distribution By Ownership Type"}
                      fields={["AmountIssuedInBirr", "OwnershipType"]}
                      title="Distribution by Ownership Type"
                      style={{
                        height:'15rem',
                        width: '32rem',
                        padding: "0rem 1rem 1rem 1rem",
                      }}
                    />
                  </ChartWrapper>
                </StandardChartWrapper>

                <LongChartWrapper>
                  <GroupedStackedBarChart
                    data={orderFillRate}
                    data1={supplierFillRate}
                    label={["Hub Met", "Facility met"]}
                    fields={["COFR", "HOFR"]}
                    label1={["Center"]}
                    fields1={["CSFR"]}
                    style={{
                      padding: "1rem",
                      position: "relative",
                      width: "55vw",
                      height: "21.4rem",
                      background: "white",
                    }}
                    chart_options={{
                      showExpandAlt: true,
                      selectFilter: true,
                      title: "Fill Rate: Hub Order vs Client Order",
                      tooltip: {
                        callbacks: {
                          title: (item) =>
                            orderFillRate.filter(
                              (d) => d.ProductCN === item[0].label
                            )[0].ProductName,
                        },
                        mode: "index",
                      },
                      option: "ProductCN",
                    }}
                  />{" "}
                </LongChartWrapper>

                <StandardChartWrapper>
                  <ChartWrapper title="Funding Source" wgInfoId="wgDonorAgents" parameter={itemFundingParams} setParameter={setItemFundingParams} isLoading={itemFundingLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                    <CustomPieChart
                      data={itemFunding}
                      label={"Funding source"}
                      fields={["AmountInBirr", "Donor"]}
                      title="Funding Source"
                      style={{
                        position: "relative",
                        width: "20rem",
                        background: "White",
                        padding: "1rem",
                        padding: "0rem 1rem 1rem 1rem",
                      }}
                    />
                    <DataGrid
                      style={{
                        padding: "1rem",
                      }}
                      initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                      autoHeight
                      rows={parseGridRows(itemFunding, ["Donor", "AmountInBirr"])}
                      columns={parseGridColumns(["Donor", "AmountInBirr"])}
                      getRowId={(row) => row.Donor}
                      rowHeight={30}
                      pageSize={10}
                      rowsPerPageOptions={[5, 10, 25]}
                      pagination
                      animateRows={true}
                      disableColumnSelector
                      components={{ Toolbar: GridToolbar }}
                    />
                    <CustomPieChart
                      data={itemFunding}
                      label={"Funding source"}
                      fields={["AmountInBirr", "Donor"]}
                      title="Funding Source"
                      style={{
                        height:'15rem',
                        width: '32rem',
                        padding: "0rem 1rem 1rem 1rem",
                      }}
                    />
                  </ChartWrapper>
                </StandardChartWrapper>
                <TableWrapper>
                  <CustomBarChartV2
                    datas={[stockUtilization.map((st) => {
                      const percentageMap = ["IssuedAmtBirr","ReservedAmtBirr","SOHAmtBirr","ExpiredAmtBirr","DamagedAmtBirr", "SuspendedAmtBirr"].reduce((percentages, field) => {
                        const fieldValue = st[field] || 0;
                        const fieldPercentage = parseFloat(fieldValue)*100 / parseFloat(st.ReceivedAmtBirr);
                        return { ...percentages, [field + 'Percentage']: FormatDecimal(fieldPercentage) };
                      }, {});

                      return { ...st, ...percentageMap };
                    }).map((su) => ({
                      ...su, 
                      IssuedAmtBirrAggregatePercentage: FormatDecimal(parseFloat(su.IssuedAmtBirrPercentage)+parseFloat(su.ReservedAmtBirrPercentage)), ExpiredAmtBirrAggregatePercentage: FormatDecimal(parseFloat(su.ExpiredAmtBirrPercentage)+parseFloat(su.DamagedAmtBirrPercentage)+parseFloat(su.SuspendedAmtBirrPercentage)),
                      IssuedAmtBirrAggregate: parseFloat(su.IssuedAmtBirr)+parseFloat(su.ReservedAmtBirr), ExpiredAmtBirrAggregate: parseFloat(su.ExpiredAmtBirr)+parseFloat(su.DamagedAmtBirr)+parseFloat(su.SuspendedAmtBirr)
                      }))]}
                    labels={[["SOH", "Expired", "Issued"]]}
                    fields={[
                      [
                        "SOHAmtBirrPercentage",
                        "ExpiredAmtBirrAggregatePercentage",
                        "IssuedAmtBirrAggregatePercentage"
                      ],
                    ]}
                    styles={[
                      {
                        padding: "1rem",
                        position: "relative",
                        minHeight: "20.8rem",
                      },
                    ]}
                    chart_options={{
                      title: "Stock Utilization: National",
                      showExpandAlt: true,
                      tooltips: ["ProductNameSH"],
                      showAmount: true,
                      customTooltips: [
                        "SOHAmtBirr",
                        "ExpiredAmtBirrAggregate",
                        "IssuedAmtBirrAggregate",
                      ],
                      option: "ProductCN",
                      parseForGridWithMain: true,
                      yTitle: "Birr Amount %",
                      wgInfoId: "wgStockUtilization",
                      max: 100,
                      background: [
                        "#0b7075",
                        "#c4b8a6",
                        "#12b3b7",
                        "#15616d",
                        "#ffddad"
                      ]
                    }}
                    chartTypes={["bar-chart"]}
                    changeLoading={stockUtilizationLoading}
                  />
                </TableWrapper>
                  <TableWrapper>
                    <CustomBarChartV2
                      datas={[shareBySite, idealShareBySite, shareBySite]}
                      labels={[
                        [
                          "AddisAbaba2 H",
                          "AddisAbaba H",
                          "Adama H",
                          "ArbaMinch H",
                          "Assosa H",
                          "BahirDar H",
                          "HomeOffice H",
                          "DireDawa H",
                          "Dessie H",
                          "Gambella H",
                          "Gondar H",
                          "Hawassa H",
                          "Jigjigia H",
                          "Jimma H",
                          "Mekele H",
                          "NegeleBoren H",
                          "Nekemte H",
                          "Semera H",
                          "Shire H",
                        ],
                        [
                          "AddisAbaba2 H",
                          "AddisAbaba H",
                          "Adama H",
                          "ArbaMinch H",
                          "Assosa H",
                          "BahirDar H",
                          "HomeOffice H",
                          "DireDawa H",
                          "Dessie H",
                          "Gambella H",
                          "Gondar H",
                          "Hawassa H",
                          "Jigjigia H",
                          "Jimma H",
                          "Mekele H",
                          "NegeleBoren H",
                          "Nekemte H",
                          "Semera H",
                          "Shire H",
                        ],
                        [
                          "AddisAbaba2 H",
                          "AddisAbaba H",
                          "Adama H",
                          "ArbaMinch H",
                          "Assosa H",
                          "BahirDar H",
                          "HomeOffice H",
                          "DireDawa H",
                          "Dessie H",
                          "Gambella H",
                          "Gondar H",
                          "Hawassa H",
                          "Jigjigia H",
                          "Jimma H",
                          "Mekele H",
                          "NegeleBoren H",
                          "Nekemte H",
                          "Semera H",
                          "Shire H",
                        ],
                      ]}
                      fields={[
                        [
                          "AA2PH",
                          "AAPH",
                          "ADPH",
                          "AMPH",
                          "ASPH",
                          "BDPH",
                          "CNPH",
                          "DDPH",
                          "DSPH",
                          "GAPH",
                          "GNPH",
                          "HWPH",
                          "JGPH",
                          "JMPH",
                          "MKPH",
                          "NBPH",
                          "NKPH",
                          "SEPH",
                          "SHPH",
                        ],
                        [
                          "AA2PH",
                          "AAPH",
                          "ADPH",
                          "AMPH",
                          "ASPH",
                          "BDPH",
                          "CNPH",
                          "DDPH",
                          "DSPH",
                          "GAPH",
                          "GNPH",
                          "HWPH",
                          "JGPH",
                          "JMPH",
                          "MKPH",
                          "NBPH",
                          "NKPH",
                          "SEPH",
                          "SHPH",
                        ],
                        [
                          "AA2PH",
                          "AAPH",
                          "ADPH",
                          "AMPH",
                          "ASPH",
                          "BDPH",
                          "CNPH",
                          "DDPH",
                          "DSPH",
                          "GAPH",
                          "GNPH",
                          "HWPH",
                          "JGPH",
                          "JMPH",
                          "MKPH",
                          "NBPH",
                          "NKPH",
                          "SEPH",
                          "SHPH",
                        ],
                      ]}
                      styles={[
                        {
                          padding: "1rem",
                          position: "relative",
                          height: "19.5vw",
                          background: "white",
                        },
                        {
                          padding: "1rem",
                          position: "relative",
                          height: "19.5vw",
                          background: "white",
                        },
                        {
                          padding: "1rem",
                          position: "relative",
                          height: "19.5vw",
                          background: "white",
                        },
                      ]}
                      chart_options={{
                        title: "National MOS",
                        showExpandAlt: true,
                        tooltips: ["ItemCTSH", "ItemCTSH", "ItemCTSH"],
                        option: "ProductCN",
                        parseForGrid: true,
                        wgInfoId: "wgNationalMos",
                        yTitle: "MOS",
                        showHubFilter: false,
                        setEnvironment: setEnvironment,
                        annotationLines: [13, 6],
                        annotationTabs: [[true, false, false]]
                      }}
                      tabTitles={["Actual", "Ideal", "Actual vs Ideal"]}
                      chartTypes={["bar-chart", "bar-chart", "stacked-chart"]}
                      changeLoading={shareBySiteLoading}
                    />
                  </TableWrapper>

                  <TableWrapper>
                    <CustomDataGrid
                      component_config={{
                        title: "Manufacturers",
                        data_source_api: PROGRAM_MANUFACTURER_API,
                        data_property: "Data",
                        columns: MANUFACTURER_COLUMN,
                        notes: "*showing 24 months of data",
                        isSOH: false,
                        wgInfoId: 'wgManufacturerGrid',
                        use_default_filter_params: false,
                        defaultFilterParams: loadFilterToDefaultParams(
                          {...MANUFACTURER_PARAM, ProgramCode: programCode},
                          { hasDataFilter: false }
                        ),
                      }}
                    />
                  </TableWrapper>
                  
                  <TableWrapper>
                    <CustomDataGrid
                      component_config={{
                        title: "Countries",
                        data_source_api: COUNTRY_API,
                        data_property: "Data",
                        columns: COUNTRY_COLUMN,
                        notes: "*showing 24 months of data",
                        isSOH: false,
                        wgInfoId: 'wgItemByCountry',
                        use_default_filter_params: false,
                        defaultFilterParams: loadFilterToDefaultParams(
                          {...PROGRAM_PARAM, ProgramCode: programCode},
                          { hasDataFilter: false }
                        ),
                      }}
                    />
                  </TableWrapper>
                </>
              )}

              {/* <TableWrapper>
                <CustomDataGrid
                  component_config={{
                    title: "Supplier",
                    data_source_api: SUPPLIER_API,
                    data_property: "Data",
                    columns: SUPPLIER_COLUMN,
                    isSOH: false,
                    wgInfoId: 'wgItemBySupplierGrid',
                    use_default_filter_params: false,
                    defaultFilterParams: loadFilterToDefaultParams(
                      PROGRAM_PARAM,
                      { hasDataFilter: false }
                    ),
                  }}
                />
              </TableWrapper> */}
              {/* { programCode == "FP" && <TableWrapper>
                <CustomDataGrid
                  component_config={{
                    title: "Non-Governmental Suppliers",
                    data_source_api: NG_SUPPLIERS_API,
                    data_property: "Data",
                    columns: NG_SUPPLIERS_COLUMN,
                    isSOH: false,
                    use_default_filter_params: false,
                    select_filter_config: [ACCOUNT_SELECT_CONFIG],
                    defaultFilterParams: loadFilterToDefaultParams(
                      {
                      },
                      { hasDateFilter: false }
                    ),
                    pageSize: 25,
                    striped: true,
                    vertical_border: true,
                  }}
                />
              </TableWrapper>} */}
          </GridContainer>
        </div>
      </div>
      {manufacturerItemsModalOpen && <ManufacturerItemsModal open={manufacturerItemsModalOpen} handleClose={setManufacturerItemsModalOpen} manufacturer={modalManufacturer} programCode={programCode}/>}
      {countryItemsModalOpen && <CountryItemsModal open={countryItemsModalOpen} handleClose={setCountryItemsModalOpen} countryCode={modalCountry} programCode={programCode} modeCode={modeCode}/>}
      {stockItemModalOpen && <StockItemsModal open={stockItemModalOpen} handleClose={setStockItemModalOpen} productSN={modalItem} modeCode={modeCode}/>}
      
      {issueTrendModalOpen && <IssueTrendModal open={issueTrendModalOpen} handleClose={setIssueTrendModalOpen} data={{ productSN: issueTrendModalData?.row?.ItemSN, row: issueTrendModalData?.row }}/>}
    </Wrapper>
  );
}

const Llin = ({headerName, programCode, loadFilterToDefaultParams, modeCode}) => {
  const [distribution, setDistribution] = useState("Center to Hub"); 
  const {data: { data: { Data:  [headerInfo] }} = { data: { Data: [] }}, isFetching: headerInfoLoading} = useQuery({
    queryKey: ['headerInfo', modeCode, programCode],
    queryFn: () => getHeaderInformation(modeCode, programCode),
    staleTime: 10800000,
  });
  const [itemFundingParams, setItemFundingParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  itemFunding }} = { data: { Data: [] }}, isFetching: itemFundingLoading} = useQuery({
    queryKey: ['itemFunding', modeCode, programCode, itemFundingParams],
    queryFn: () => ItemFunding(modeCode, programCode, itemFundingParams),
    staleTime: 10800000,
  });
  const [distByTypeParams, setDistByTypeParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  distByType }} = { data: { Data: [] }}, isFetching: distByTypeLoading} = useQuery({
    queryKey: ['distByType', modeCode, programCode, distByTypeParams],
    queryFn: () => getDistributionByFacilityType(modeCode, programCode, distByTypeParams),
    staleTime: 10800000,
  });

  return (<Wrapper>
      <NavBar title={`Fanos ${headerName} Program Dashboard`} />
      {/* <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: "10",
          textAlign: "center",
          padding: "1rem",
          color: "white",
          display: "flex",
          flexDirection: "row",
          background: "#15616d",
          boxShadow: "0 2px 5px 0 grey",
        }}
      >
        <Button
          style={{ color: "white" }}
          onClick={() => {
            history("/");
          }}
        >
          Home
        </Button>
        <div
          style={{
            textAlign: "center",
            marginLeft: " 20rem",
          }}
        >
          <h4>Fanos {headerName} Program Dashboard</h4>
        </div>
      </div> */}
      <div className="container-context">
        <Box width="16%">
          <LlinSideNav programCode={programCode} />
        </Box>
        <Box display="block" flex="1">
          <div className="card-container mt-3">
            <CustomCard
              widget_config={{
                title: "SOH",
                value: headerInfo ? headerInfo.SOH.toLocaleString() : 0,
                logo_url: sohPic,
              }}
              Icon={MdMedicalServices}
            />
            <CustomCard
              widget_config={{
                title: "Ordered",
                value: headerInfo
                  ? headerInfo.UnderOrderQuantity.toLocaleString()
                  : 0,
                logo_url: stackPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "GIT",
                value: headerInfo
                  ? headerInfo.GITQuantity.toLocaleString()
                  : 0,
                logo_url: gitPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "Damaged",
                value: headerInfo
                  ? headerInfo.QuantityDamaged.toLocaleString()
                  : 0,
                logo_url: dmgPic,
              }}
            />
            <Box display="flex" alignItems="center">
            <button
              className="button"
              onClick={() => {
                window.open("https://llin.mbrana.org/", '_blank');
              }}
            >
              Go To LLIN
            </button>
            </Box>
          </div>
          <GridContainerAlternate>
            <StandardChartWrapperAlternate>
              <ChartWrapper title="Funding Source" wgInfoId="wgDonorAgents" parameter={itemFundingParams} setParameter={setItemFundingParams} isLoading={itemFundingLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]} pad={[0, "auto"]}>
                <CustomPieChart
                  data={itemFunding}
                  label={"Funding source"}
                  fields={["AmountInBirr", "Donor"]}
                  title="Funding Source"
                  style={{
                    position: "relative",
                    width: "20rem",
                    margin: '0px auto',
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(itemFunding, ["Donor", "AmountInBirr"])}
                  columns={parseGridColumns(["Donor", "AmountInBirr"])}
                  getRowId={(row) => row.Donor}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={itemFunding}
                  label={"Funding source"}
                  fields={["AmountInBirr", "Donor"]}
                  title="Funding Source"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapperAlternate>
            <StandardChartWrapperAlternate>
              <ChartWrapper title="Distribution by Facility Type" wgInfoId="wgDistrByFacilitytypeMt" parameter={distByTypeParams} setParameter={setDistByTypeParams} isLoading={distByTypeLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]} pad={[0, "auto"]}>
                <CustomPieChart
                  data={distByType}
                  label={"Distribution by facility type"}
                  fields={["AmountIssuedInBirr", "InstitutionType"]}
                  title="Distribution by Facility Type"
                  style={{
                    position: "relative",
                    width: "20rem",
                    margin: '0px auto',
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByType, ["InstitutionType", "AmountIssuedInBirr"])}
                  columns={parseGridColumns(["InstitutionType", "AmountIssuedInBirr"])}
                  getRowId={(row) => row.InstitutionType}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByType}
                  label={"Distribution by facility type"}
                  fields={["AmountIssuedInBirr", "InstitutionType"]}
                  title="Distribution by Facility Type"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapperAlternate>

            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: "Stock Status",
                  data_source_api: STOCK_STATUS_API,
                  data_property: "Data",
                  columns: STOCK_STATUS_COLUMN,
                  isSOH: false,
                  pageSize: 25,
                  wgInfoId: 'wgSohAmcMosByItem',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...STOCK_STATUS_DEFAULT_PARAM, ProgramCode: programCode},
                    { hasDataFilter: false }
                  ),
                }}
              />
            </TableWrapperAlternate>
            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: `Issued - ${distribution}`,
                  data_source_api: distribution == 'Center to Hub'? CENTER_TO_HUB_API: HUB_TO_CENTER_API,
                  data_property: "Data",
                  columns: ISSUED_COLUMN, 
                  isSOH: false,
                  wgInfoId: 'wgItemDistributionByProgram',
                  use_default_filter_params: false,
                  select_filter_config: [HUB],
                  distribution_filter: {showDistributionFilter: true, setDistribution: setDistribution, distribution: distribution},
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...CENTER_TO_HUB_DEFAULT_PARAM, ModeCode: modeCode},
                    { hasDateRangeFilter: true }
                  ),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                  },
                }}
              />
            </TableWrapperAlternate>
            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: "Purchase Orders and Shipments",
                  data_source_api: MANUFACTURER_LLIN_API,
                  data_property: "Data",
                  columns: MANUFACTURER_LLIN_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wgSohAmcMosByItemLlin',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    MANUFACTURER_LLIN_PARAM,
                    { hasDateFilter: false }
                  ),
                }}
              />
            </TableWrapperAlternate>
            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: "Distribution : Planned Vs Actual",
                  data_source_api: DISTRIBUTION_API,
                  data_property: "Data",
                  columns: DISTRIBUTION_COLUMN,
                  isSOH: false,
                  sorted: "Region",
                  wgInfoId: 'wgLlinDistributionPlannedVsActual',
                  use_default_filter_params: false,
                  select_filter_config: [YEAR_SELECT_CONFIG],
                  defaultFilterParams: loadFilterToDefaultParams(
                    {
                      ...MANUFACTURER_LLIN_PARAM,
                      Year: 2016,
                    },
                    { hasDateFilter: false }
                  ),
                }}
              />
            </TableWrapperAlternate>
            {/* <TableWrapperAlternate>
              <Maps param={{ProgramCode: programCode}} modeCodeParam={{ModeCode: modeCode}} page={"Program"} />
            </TableWrapperAlternate> */}
          </GridContainerAlternate>
        </Box>
      </div>
    </Wrapper>)
}

const Shi = ({headerName, programCode, loadFilterToDefaultParams, modeCode, setModeCode}) => {
  const {data: { data: { Data:  [shiHeaderInfo] }} = { data: { Data: [] }}, isFetching: shiHeaderInfoLoading} = useQuery({
    queryKey: ['shiHeaderInfo', modeCode, programCode],
    queryFn: () => getShiHeader(modeCode, programCode),
    staleTime: 10800000,
  });
  const defaultRDF = ["AC"];
  const [checked, setChecked] = useState(defaultRDF.includes(programCode));

  const shiProductModalOpen = useFanosStore((state) => state.shiProductModalOpen);
  const modalProduct = useFanosStore((state) => state.modalProduct);
  const setShiProductModalOpen = useFanosStore((state) => state.setShiProductModalOpen);

  return (<Wrapper>
      <NavBar title={`Fanos ${headerName} Program Dashboard`} />
      {/* <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: "10",
          textAlign: "center",
          padding: "1rem",
          color: "white",
          display: "flex",
          flexDirection: "row",
          background: "#15616d",
          boxShadow: "0 2px 5px 0 grey",
        }}
      >
        <Button
          style={{ color: "white" }}
          onClick={() => {
            history("/");
          }}
        >
          Home
        </Button>
        <div
          style={{
            textAlign: "center",
            marginLeft: " 20rem",
          }}
        >
          <h4>Fanos {headerName} Program Dashboard</h4>
        </div>
      </div> */}
      <div className="container-box">
        {" "}
        <div className="card-container mt-3" style={{justifyContent: "center"}}>
          <CustomCard
            widget_config={{
              title: "Items",
              value: shiHeaderInfo ? shiHeaderInfo.ItemCount.toLocaleString() : 0,
              logo_url: sohPic,
            }}
            Icon={MdMedicalServices}
          />
          <CustomCard
            widget_config={{
              title: "Last Receipt",
              value: shiHeaderInfo
                ? shiHeaderInfo.AmountReceivedInBirr.toLocaleString()
                : 0,
              logo_url: stackPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Last Issued",
              value: shiHeaderInfo
                ? shiHeaderInfo.AmountIssuedInBirr.toLocaleString()
                : 0,
              logo_url: gitPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Last Receipt",
              value: shiHeaderInfo
                ? moment(shiHeaderInfo.LastReceivedDate).fromNow()
                : 0,
              logo_url: expPic,
              dateFormat: true,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Last Issued",
              value: shiHeaderInfo
                ? moment(shiHeaderInfo.LastIssuedDate).fromNow()
                : 0,
              logo_url: qexpPic,
              dateFormat: true,
            }}
          />
          <FormControlLabel control={
            <Switch
              onChange={(e)=> {
                setChecked(e.target.checked);
                e.target.checked? setModeCode('RDF'): setModeCode('HPR');
              }} />} 
            label={checked?"RDF": "Health Program"} 
            style={{marginLeft: "10px"}} />
        </div>
        <GridContainer>
          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "Item",
                data_source_api: SHI_ITEM_API,
                data_property: "Data",
                columns: SHI_ITEM_COLUMN,
                isSOH: false,
                wgInfoId: 'wg-shi-detail-by-product',
                use_default_filter_params: false,
                defaultFilterParams: loadFilterToDefaultParams(
                  {...SHI_ITEM_DEFAULT_PARAM, ModeCode: modeCode},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                },
              }}
            />
          </TableWrapper>
        </GridContainer>
      </div>
      {shiProductModalOpen && <ShiProductModal open={shiProductModalOpen} handleClose={setShiProductModalOpen} productCode={modalProduct} programCode={programCode} modeCode={modeCode}/>}
    </Wrapper>)
}

const BloodBankPrograms = ({headerName, loadFilterToDefaultParams, modeCode}) => {
  const stockQuantityModalOpen = useFanosStore((state) => state.stockQuantityModalOpen);
  const modalQuantity = useFanosStore((state) => state.modalQuantity);
  const setStockQuantityModalOpen = useFanosStore((state) => state.setStockQuantityModalOpen);
  const stockExpiryModalOpen = useFanosStore((state) => state.stockExpiryModalOpen);
  const modalExpiry = useFanosStore((state) => state.modalExpiry);
  const setStockExpiryModalOpen = useFanosStore((state) => state.setStockExpiryModalOpen);

  return (<Wrapper>
      <NavBar title={`Fanos ${headerName} Program Dashboard`} />
      <GridContainer>
        <TableWrapper>
          <CustomDataGrid
            component_config={{
              title: "Stock Status",
              data_source_api: STOCK_STATUS_API,
              data_property: "Data",
              columns: STOCK_STATUS_BLOOD_BANK_COLUMN,
              isSOH: false,
              wgInfoId: 'wgSohAmcMosByItem',
              use_default_filter_params: false,
              defaultFilterParams: loadFilterToDefaultParams(
                {...STOCK_STATUS_DEFAULT_PARAM, ModeCode: modeCode},
                { hasDataFilter: false }
              ),
            }}
          />
        </TableWrapper>
      </GridContainer>
      {stockQuantityModalOpen && (<StockQuantityModal open={stockQuantityModalOpen} handleClose={setStockQuantityModalOpen} productSN={modalQuantity} />)}
      {stockExpiryModalOpen && (<StockExpiryModal open={stockExpiryModalOpen} handleClose={setStockExpiryModalOpen} productSN={modalExpiry} />)}
    </Wrapper>)
}

const AlternateUI = ({headerName, programCode, loadFilterToDefaultParams, modeCode, setModeCode}) => {
  const [distByTypeParams, setDistByTypeParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  distByType }} = { data: { Data: [] }}, isFetching: distByTypeLoading} = useQuery({
    queryKey: ['distByType', modeCode, programCode, distByTypeParams],
    queryFn: () => getDistributionByFacilityType(modeCode, programCode, distByTypeParams),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  [headerInfo] }} = { data: { Data: [] }}, isFetching: headerInfoLoading} = useQuery({
    queryKey: ['headerInfo', modeCode, programCode],
    queryFn: () => getHeaderInformation(modeCode, programCode),
    staleTime: 10800000,
  });
  const [distByOwnershipParams, setDistByOwnershipParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  distByOwnership }} = { data: { Data: [] }}, isFetching: distByOwnershipLoading} = useQuery({
    queryKey: ['distByOwnership', modeCode, programCode, distByOwnershipParams],
    queryFn: () => getDistributionByOwnershipType(modeCode, programCode, distByOwnershipParams),
    staleTime: 10800000,
  });
  const [itemProcurementParams, setItemProcurementParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  itemProcurement }} = { data: { Data: [] }}, isFetching: itemProcurementLoading} = useQuery({
    queryKey: ['itemProcurement', modeCode, programCode, itemProcurementParams],
    queryFn: () => ItemProcurer(modeCode, programCode, itemProcurementParams),
    staleTime: 10800000,
  });
  const [itemFundingParams, setItemFundingParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  itemFunding }} = { data: { Data: [] }}, isFetching: itemFundingLoading} = useQuery({
    queryKey: ['itemFunding', modeCode, programCode, itemFundingParams],
    queryFn: () => ItemFunding(modeCode, programCode, itemFundingParams),
    staleTime: 10800000,
  });

  const defaultRDF = ["AC"];
  const [checked, setChecked] = useState(defaultRDF.includes(programCode));
  const [distribution, setDistribution] = useState("Center to Hub");

  const stockItemModalOpen = useFanosStore((state) => state.stockItemModalOpen);
  const modalItem = useFanosStore((state) => state.modalItem);
  const setStockItemModalOpen = useFanosStore((state) => state.setStockItemModalOpen);

  return (<Wrapper>
      <NavBar title={`Fanos ${headerName} Program Dashboard`} />
      {/* <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: "10",
          textAlign: "center",
          padding: "1rem",
          color: "white",
          display: "flex",
          flexDirection: "row",
          background: "#15616d",
          boxShadow: "0 2px 5px 0 grey",
        }}
      >
        <Button
          style={{ color: "white" }}
          onClick={() => {
            history("/");
          }}
        >
          Home
        </Button>
        <div
          style={{
            textAlign: "center",
            marginLeft: " 20rem",
          }}
        >
          <h4>Fanos {headerName} Program Dashboard</h4>
        </div>
      </div> */}
      <div className="container-box">
        {" "}
        <div className="card-container mt-3">
          <CustomCard
            widget_config={{
              title: "SOH",
              value: headerInfo ? headerInfo.SOH.toLocaleString() : 0,
              logo_url: sohPic,
            }}
            Icon={MdMedicalServices}
          />
          <CustomCard
            widget_config={{
              title: "Ordered",
              value: headerInfo
                ? headerInfo.UnderOrderQuantity.toLocaleString()
                : 0,
              logo_url: stackPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "GIT",
              value: headerInfo
                ? headerInfo.GITQuantity.toLocaleString()
                : 0,
              logo_url: gitPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Expired",
              value: headerInfo
                ? headerInfo.QuantityExpired.toLocaleString()
                : 0,
              logo_url: expPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "nExpiry",
              value: headerInfo
                ? headerInfo.L3month.toLocaleString()
                : 0,
              logo_url: qexpPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Damaged",
              value: headerInfo
                ? headerInfo.QuantityDamaged.toLocaleString()
                : 0,
              logo_url: dmgPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Gap",
              value: headerInfo ? headerInfo.Gap.toLocaleString() : 0,
              logo_url: gapPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Overage",
              value: headerInfo
                ? headerInfo.Overage.toLocaleString()
                : 0,
              logo_url: ovrgPic,
            }}
          />
          <FormControlLabel control={
            <Switch 
              defaultChecked={defaultRDF.includes(programCode)?true: false} 
              onChange={(e)=> {
                setChecked(e.target.checked);
                e.target.checked? setModeCode('RDF'): setModeCode('HPR');
              }} />} 
            label={checked?"RDF": "Health Program"} 
            style={{marginLeft: "10px"}} />
        </div>
        <GridContainerAlternate>
          <StandardChartWrapperAlternate>
              <ChartWrapper title="Distribution by Facility Type" wgInfoId="wgDistrByFacilitytypeMt" parameter={distByTypeParams} setParameter={setDistByTypeParams} isLoading={distByTypeLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                <CustomPieChart
                  data={distByType}
                  label={"Distribution by facility type"}
                  fields={["AmountIssuedInBirr", "InstitutionType"]}
                  title="Distribution by Facility Type"
                  style={{
                    position: "relative",
                    width: "20rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                    margin: "0 auto",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByType, ["InstitutionType", "AmountIssuedInBirr"])}
                  columns={parseGridColumns(["InstitutionType", "AmountIssuedInBirr"])}
                  getRowId={(row) => row.InstitutionType}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByType}
                  label={"Distribution by facility type"}
                  fields={["AmountIssuedInBirr", "InstitutionType"]}
                  title="Distribution by Facility Type"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapperAlternate>
            <StandardChartWrapperAlternate>
              <ChartWrapper title="Distribution By Ownership Type" wgInfoId="wgDistrByOwnershiptype" parameter={distByOwnershipParams} setParameter={setDistByOwnershipParams} isLoading={distByOwnershipLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]} pad={[0, "auto"]}>
                <CustomPieChart
                  data={distByOwnership}
                  label={"Distribution By Ownership Type"}
                  fields={["AmountIssuedInBirr", "OwnershipType"]}
                  title="Distribution by Ownership Type"
                  style={{
                    position: "relative",
                    width: "20rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByOwnership, ["OwnershipType", "AmountIssuedInBirr",])}
                  columns={parseGridColumns(["OwnershipType", "AmountIssuedInBirr",] )}
                  getRowId={(row) => row.OwnershipType}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByOwnership}
                  label={"Distribution By Ownership Type"}
                  fields={["AmountIssuedInBirr", "OwnershipType"]}
                  title="Distribution by Ownership Type"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapperAlternate>

            <StandardChartWrapperAlternate>
              <ChartWrapper title="Procurement Agents" wgInfoId="wgProcurerAgents" parameter={itemProcurementParams} setParameter={setItemProcurementParams} isLoading={itemProcurementLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]} pad={[0, "auto"]}>
                <CustomPieChart
                  data={itemProcurement}
                  label={"Procurement Agent"}
                  fields={["AmountInBirr", "ProcurmentAgent"]}
                  title="Procurement Agents"
                  style={{
                    position: "relative",
                    width: "20rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(itemProcurement, [ "ProcurmentAgent", "AmountInBirr"])}
                  columns={parseGridColumns(["ProcurmentAgent", "AmountInBirr"])}
                  getRowId={(row) => row.ProcurmentAgent}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={itemProcurement}
                  label={"Procurement Agent"}
                  fields={["AmountInBirr", "ProcurmentAgent"]}
                  title="Procurement Agents"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapperAlternate>
            <StandardChartWrapperAlternate>
              <ChartWrapper title="Funding Source" wgInfoId="wgDonorAgents" parameter={itemFundingParams} setParameter={setItemFundingParams} isLoading={itemFundingLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]} pad={[0, "auto"]}>
                <CustomPieChart
                  data={itemFunding}
                  label={"Funding source"}
                  fields={["AmountInBirr", "Donor"]}
                  title="Funding Source"
                  style={{
                    position: "relative",
                    width: "20rem",
                    background: "White",
                    padding: "1rem",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(itemFunding, ["Donor", "AmountInBirr"])}
                  columns={parseGridColumns(["Donor", "AmountInBirr"])}
                  getRowId={(row) => row.Donor}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={itemFunding}
                  label={"Funding source"}
                  fields={["AmountInBirr", "Donor"]}
                  title="Funding Source"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapperAlternate>

            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: "Stock Status",
                  data_source_api: STOCK_STATUS_API,
                  data_property: "Data",
                  columns: STOCK_STATUS_COLUMN_EXPAND,
                  isSOH: false,
                  wgInfoId: 'wgSohAmcMosByItem',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...STOCK_STATUS_DEFAULT_PARAM, ModeCode: modeCode},
                    { hasDataFilter: false }
                  ),
                }}
              />
            </TableWrapperAlternate>
            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: `Issued - ${distribution}`,
                  data_source_api: distribution == 'Center to Hub'? CENTER_TO_HUB_API: HUB_TO_CENTER_API,
                  data_property: "Data",
                  columns: ISSUED_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wgItemDistributionByProgram',
                  use_default_filter_params: false,
                  distribution_filter: {showDistributionFilter: true, setDistribution: setDistribution, distribution: distribution},
                  select_filter_config: [HUB],
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...CENTER_TO_HUB_DEFAULT_PARAM, ModeCode: modeCode},
                    { hasDateRangeFilter: true }
                  ),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                  },
                }}
              />
            </TableWrapperAlternate>
            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: "Manufacturer",
                  data_source_api: MANUFACTURER_API,
                  data_property: "Data",
                  columns: MANUFACTURER_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wgManufacturerGrid',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...MANUFACTURER_PARAM, ModeCode: modeCode},
                    { hasDataFilter: false }
                  ),
                }}
              />
            </TableWrapperAlternate>
            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: "Supplier",
                  data_source_api: SUPPLIER_API,
                  data_property: "Data",
                  columns: SUPPLIER_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wgItemBySupplierGrid',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...PROGRAM_PARAM, ModeCode: modeCode},
                    { hasDataFilter: false }
                  ),
                }}
              />
            </TableWrapperAlternate>
            <TableWrapperAlternate>
              <CustomDataGrid
                component_config={{
                  title: "Country",
                  data_source_api: COUNTRY_API,
                  data_property: "Data",
                  columns: COUNTRY_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wgItemByCountry',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...PROGRAM_PARAM, ModeCode: modeCode},
                    { hasDataFilter: false }
                  ),
                }}
              />
            </TableWrapperAlternate>  
        </GridContainerAlternate>
      </div>
      {stockItemModalOpen && <StockItemsModal open={stockItemModalOpen} handleClose={setStockItemModalOpen} productSN={modalItem} modeCode={modeCode}/>}
    </Wrapper>)
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapper = styled.div`
    display: flex;
    grid-column: span 3;
`;

const StandardChartWrapper = styled.div`
    display: flex;
    grid-column: span 1;
`;

const LongChartWrapper = styled.div`
    display: flex;
    grid-column: span 2;
`;

const GridContainerAlternate = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapperAlternate = styled.div`
    display: flex;
    grid-column: span 2;
`;

const StandardChartWrapperAlternate = styled.div`
    display: flex;
    grid-column: span 1;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
  

  .tab-ul-admin {
    list-style-type: none;
    align-items: center;
    border-radius: 0.3rem;
    background-color: rgb(237, 241, 249);
    padding: 0;
    max-width: 30rem;
    min-width: 20rem;
  }
  
  .tab-li {
    flex-grow: 1;
  }
  
  .tab-li a {
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 0.15rem;
    padding: 0.35rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.15rem;
  }
  
  .tab-li-active a {
    background: white;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px 0px;
  }
  
`;


