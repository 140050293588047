import React, {
  useState
} from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// custom components
import CustomLoadingIndicator from "../Plugins/CustomLoadingIndicator";

import { IsEmpty } from "../Helpers/FormatHelper";

function Thumbnail({ widget_config, Icon }) {
  const history = useNavigate();
  const defaultProgram = {
    ProgramGroupID:	805,
    ProgramID:	94,
    ProgramName:	"Family Planning",
    GroupID:	27,
    GroupName:	"Administrator",
    ProgramCode: "FP"
  }

  return (
    <Wrapper>

      <div
        className="grow-1"
        style={widget_config.value == null ? { opacity: "0.8" } : {}}
      >
        <div
          className="thumbnail-main-container custom-card-container flex-column"
          style={{ justifyContent: "space-between" }}
        >
          <div className="widget-header-container flex-column">
            <div className="thumbnail">
              <img 
                  onClick={(e)=> {
                    if(!IsEmpty(widget_config.url)) {
                      switch (widget_config.type) {
                        case "external":
                          if(widget_config.passUserData)
                            window.open(widget_config.url + '/' + new URLSearchParams(JSON.parse(localStorage.getItem("userInfo"))).toString(), '_blank');
                          else
                            window.open(widget_config.url, "_blank", "noopener,noreferrer");
                          break;
                      
                        default:
                          history(widget_config.url, 
                            widget_config.url_data ? { state: widget_config.url_data} : {});
                          break;
                      }
                    }
                  }}
                  className="thumbnail-body" width="195" 
                  src={"/assets/thumbnail/" + (widget_config.img_src ? widget_config.img_src : (widget_config.title.toLowerCase() + '.png')) } alt={widget_config.title} />
            </div>
          </div>
          <div className="flex-row">
            <Icon 
                size={20} 
                className="thumbnail-icon" 
                style={{color: "#15616d"}}
                title={widget_config.tooltip}
            />{" "}
            <span className="thumbnail-header">
              {widget_config.title}
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Thumbnail;

const Wrapper = styled.div`
    margin: 0.5rem 0rem;
    .thumbnail {
      min-width: 10rem;
      border-radius: 1rem;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    .thumbnail-body {
      border-radius: 0.75rem; 
      min-height: 7.3rem;
    }
    .thumbnail-body:hover {
      border-radius: 0rem;
      cursor: pointer;
      pointer-events: all;
    }
    .thumbnail-main-container {
      padding: 0rem 0.25rem;

    .thumbnail-header {
      font-size: 1rem;
      line-height: 2.2rem;
      font-weight: 500;
    }

    .thumbnail-icon {
      align-self: center;
      margin: 0 0.25rem;
    }
  }
`;
