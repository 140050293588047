import React, { cloneElement, useRef, useState } from "react";
import "chart.js/auto";
import { FaExpandAlt, FaPrint } from "react-icons/fa";
import { Modal, Typography } from "@mui/material";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Box } from "@mui/system";
import InfoIcon from '@mui/icons-material/Info';
import { GridInfo } from "../Shared Utils/InfoGrid";
import CustomChartSelect from "../Shared Utils/CustomChartSelect";
import Dots from "../../../assets/GIFs/dotss.gif";
import CustomDatePicker from "../Shared Utils/CustomDatePicker";
import { useReactToPrint } from "react-to-print";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { IsEmpty } from "../Shared Utils/FormatHelpers";

export default function ChartWrapper({children, handleChange, parameter, setParameter, title, wgInfoId, showYearFilter, selectFilterConfig, localFilterConfig, defaultFilterParams, datePicker, pad, isLoading, style }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const chartFilterParams = defaultFilterParams;
  const year = ["2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009", "2008"];
  const [dialogOpen, setDialogOpen] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton style={{ color: "cadetblue" }} />
        <GridToolbarDensitySelector style={{ color: "cadetblue" }} />
        <GridToolbarExport csvOptions={{fileName: title}}  style={{ color: "cadetblue" }} />
      </GridToolbarContainer>
    );
  }
  
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handle_change = (param) => {
    setParameter({...parameter, [param.paramName]: param.paramValue})
  };

  const onChangeDatePicker = (dates) => {
    setParameter({...parameter, ...dates})
  };

  const getStartDateParamName = (component_config) => {
    if(IsEmpty(component_config.start_date_param_name)){
      return "from"
    }
    return component_config.start_date_param_name;
  }

  const getEndDateParamName = (component_config) => {
    if(IsEmpty(component_config.end_date_param_name)){
      return "from"
    }
    return component_config.end_date_param_name;
  }

  const getDatePickerConfig = () => {
    if(Object.keys(parameter).includes(getStartDateParamName(datePicker))){
      datePicker.start_date = parameter[getStartDateParamName(datePicker)]
    } 
    if(Object.keys(parameter).includes(getEndDateParamName(datePicker))){
      datePicker.end_date = parameter[getEndDateParamName(datePicker)]
    } 
    return datePicker
  }

  return (
    <div className="container" style={pad? {padding: `${pad[0] === "auto"? "auto": pad[0]+"px"} ${pad[1] === "auto"? "auto": pad[1]+"px"}`, flex: 1, ...style}: {flex: 1, ...style}}>
        <Modal
            open={open}
            onClose={handleClose}
        >
        <Box className="modal-class"  style={{
                            padding: "1rem",
                            position: "relative",
                            width: "80%",
                            height: "38rem",
                            background: "white",
                            }}>
            <Typography id="modal-modal-description" display={"flex"} justifyContent={"space-between"}>
              <h6 style={{ margin: "20px" }} display={"flex"}>
                {title} -{" "}
                <FaPrint onClick={handlePrint} size={"1.2em"} />
                {isLoading && (
          <span>
            <img src={Dots} alt="" />
          </span>
        )}
              </h6>
              <Box style={{ margin: "10px" }}>
            <div className="tool-bar-item-container" style={{width: '100%', justifyContent: 'flex-start'}}>
              {selectFilterConfig?.map((config) => 
                (
                  <CustomChartSelect
                    handle_change={ handle_change}
                    component_config={parameter? Object.keys(parameter).includes(config.param_name)? {...config, selected_value: parameter[config.param_name]}: config: config}
                  />
                )
              )}
              {localFilterConfig?.map((config) => 
                (<div className="tool-bar-item" style={{flexGrow: '0', width: '8rem'}}>
                  <CustomChartSelect
                    handle_change={ handle_change}
                    component_config={parameter? Object.keys(parameter).includes(config.param_name)? {...config, selected_value: parameter[config.param_name]}: config: config}
                  />
                </div>)
              )}
              {datePicker && (
                <div className="tool-bar-item-container" style={{width: datePicker.date_range? "13.5rem": "auto", marginLeft: "10px"}}>
                  <div className="tool-bar-item" style={{maxWidth: datePicker.date_range? "13.5rem": "11rem"}}>
                    <CustomDatePicker
                      className="date-picker-input"
                      handle_change={onChangeDatePicker}
                      component_config={getDatePickerConfig()}
                    />
                  </div>
                </div>
              )}
            </div>
              </Box>
              
              
              
            
            </Typography>
            <Typography />
            <Tabs style={{ paddingTop: 10 }}>
                <TabList>
                    <Tab>Charts</Tab>
                    <Tab>Tabular</Tab>
                </TabList>
                <TabPanel>
                    <div ref={componentRef} style={{display: 'flex',justifyContent:'center',}}>
                        {children[2]? children[2]: children[0]}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div ref={componentRef}>
                        {cloneElement(children[1], { components: { Toolbar: CustomToolbar }}) }
                    </div>
                </TabPanel>
            </Tabs>
        </Box>
        </Modal>
        <div className="header" style={{display: "flex", flexDirection: "column", color: "black"}}>
            <h6 style={{width: '100%', display: 'flex', gap: '10px', zIndex: 900, fontSize: "1.1rem"}}>
                <div>{title}</div>
                <FaExpandAlt className="expand-icon" onClick={handleOpen} />
                <InfoIcon  onClick={() => setDialogOpen(true)} style={{ color: "cadetblue" }} />
            </h6>
            <div className="tool-bar-item-container" style={{width: '100%', justifyContent: 'flex-start'}}>
              {selectFilterConfig?.map((config) => 
                (
                  <CustomChartSelect
                    handle_change={ handle_change}
                    component_config={parameter? Object.keys(parameter).includes(config.param_name)? {...config, selected_value: parameter[config.param_name]}: config: config}
                  />
                )
              )}
              {localFilterConfig?.map((config) => 
                (<div className="tool-bar-item" style={{flexGrow: '0', width: '8rem'}}>
                  <CustomChartSelect
                    handle_change={ handle_change}
                    component_config={parameter? Object.keys(parameter).includes(config.param_name)? {...config, selected_value: parameter[config.param_name]}: config: config}
                  />
                </div>)
              )}
              {datePicker && (
                <div className="tool-bar-item-container" style={{width: datePicker.date_range? "13.5rem": "auto", marginLeft: "10px"}}>
                  <div className="tool-bar-item" style={{maxWidth: datePicker.date_range? "13.5rem": "11rem"}}>
                    <CustomDatePicker
                      className="date-picker-input"
                      handle_change={onChangeDatePicker}
                      component_config={getDatePickerConfig()}
                    />
                  </div>
                </div>
              )}
            </div>
        </div> 
        {isLoading && (
          <span>
            <img src={Dots} alt="" />
          </span>
        )}
        {children[0]}
        {dialogOpen && <GridInfo dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} widgetId={wgInfoId} />}
    </div>
  );
}
