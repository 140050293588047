import React, { Component, useEffect, useState } from "react";
import { FaExpandAlt } from "react-icons/fa";
import NavBar from "../../shared/NavBar";
import { MISCELLANEOUS_STOCK_API, MISCELLANEOUS_STOCK_DETAIL_API, MISCELLANEOUS_STOCK_API_SAP } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { getCommodityList, getProgramList } from "../../../Services/ApiManager";
import { NATIONAL_COMMODITY_TYPE_SELECT_CONFIG, NATIONAL_MODE_SELECT_CONFIG, NATIONAL_PROGRAM_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { FormatDecimal } from "../../../Utils/NumberUtils";
import { Button } from "@mui/material";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function getTotalMOS(params) {
//console.log(params)
  return `${FormatDecimal(params.data.LCOpenedorCADReservedMOS + params.data.AwaitingForeignCurrencyCashNotReservedMOS + params.data.RequestedMOS + params.data.NationalMOS + params.data.TMDMOS, 0)}`;
}

let columnGroupingDetailModel = [
  {
    headerName: 'Item Description',
    children: [
    {
      field: "ItemSN",
      headerName: "SN",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#fafafb'}
    },
    {
      field: "Item",
      headerName: "Item",
      width: 250,
      pinned: 'left',
      cellStyle: {'background-color': '#fafafb'}
    },
    {
      field: "Unit",
      headerName: "Unit",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#fafafb'}
    },],
  },
  {
    headerName: '',
    children: [
      {
        field: "VEN",
        headerName: "VEN",
        width: 70,
        pinned: 'left',
        cellStyle: {'background-color': '#fafafb'}
      },
      {
        field: "Activity",
        headerName: "Activity",
        width: 100,
        pinned: 'left',
        cellStyle: {'background-color': '#fafafb'}
      }],
  },
  {
    headerName: 'National',
    children: [{
      field: "NationalSOH",
      headerName: "SOH",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.NationalSOH, 0),
    },
    {
      field: "NationalDOS",
      headerName: "DOS",
      width: 60,
      valueGetter: (params) => FormatDecimal(params.data.NationalDOS, 0),
    },
    {
      field: "NationalAMC",
      headerName: "AMC",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.NationalAMC, 0),
    },
    {
      field: "MOHNationalAMC",
      headerName: "Adjusted AMC",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.MOHNationalAMC, 0),
    },
    {
      field: "NationalMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.NationalMOS, 0),
    },
    {
      field: "MOHNationalMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.MOHNationalMOS, 0),
      cellStyle: { whiteSpace: 'pre-wrap' }
    },],
  },
  {
    headerName: 'Git',
    children: [{
      field: "LCOpenedorCADReservedPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "LCOpenedorCADReservedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.LCOpenedorCADReservedQuantity, 0),
    },
    {
      field: "LCOpenedorCADReservedMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.LCOpenedorCADReservedMOS, 0),
    },
    {
      field: "LCOpenedorCADReservedMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.LCOpenedorCADReservedMOHMOS, 0),
    },],
  },
  {
    headerName: 'Prioritized POs',
    children: [{
      field: "PrioritizedPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "PrioritizedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.PrioritizedQuantity, 0),
    },
    {
      field: "PrioritizedMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.PrioritizedMOS, 0),
    },
    {
      field: "PrioritizedMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.PrioritizedMOHMOS, 0),
    },],
  },
  {
    headerName: 'Available POs',
    children: [{
      field: "AwaitingForeignCurrencyCashNotReservedPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "AwaitingForeignCurrencyCashNotReservedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AwaitingForeignCurrencyCashNotReservedQuantity, 0),
    },
    {
      field: "AwaitingForeignCurrencyCashNotReservedMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.AwaitingForeignCurrencyCashNotReservedMOS, 0),
    },
    {
      field: "AwaitingForeignCurrencyCashNotReservedMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AwaitingForeignCurrencyCashNotReservedMOHMOS, 0),
    },],
  },
  {
    headerName: 'TMD',
    children: [{
      field: "TMDPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "TMDQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.TMDQuantity, 0),
    },
    {
      field: "TMDMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.TMDMOS, 0),
    },
    {
      field: "TMDMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.TMDMOHMOS, 0),
    },],
  },
  {
    headerName: 'Requested order Quantity',
    children: [{
      field: "RequestedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.RequestedQuantity, 0),
    },
    {
      field: "RequestedMOS",
      headerName: "Requested MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.RequestedMOS, 0),
    },
    {
      field: "RequestedMOHMOS",
      headerName: "Adjusted Requested MOS",
      width: 140,
      valueGetter: (params) => FormatDecimal(params.data.RequestedMOHMOS, 0),
    },],
  },
  {
    headerName: '',
    children: [{
      field: "TotalMOS",
      headerName: "Total MOS",
      width: 80,
      valueGetter: getTotalMOS,
    },
    {
      field: "ExpiryDates",
      headerName: "Expiry Dates",
      width: 240,
    },],
  },
  {
    headerName: 'Center',
    children: [{
      field: "CenterSOH",
      headerName: "SOH",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.CenterSOH, 0),
    },],
  },
  {
    headerName: 'Center to Hub',
    children: [{
      field: "CenterToHubGIT",
      headerName: "GIT",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.CenterToHubGIT, 0),
    },],
  },
  {
    headerName: 'Hubs',
    children: [{
      field: "Adama",
      headerName: "Adama",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Adama, 0),
    },
    {
      field: "AddisAbaba",
      headerName: "Addis Ababa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AddisAbaba, 0),
    },
    {
      field: "AddisAbaba2",
      headerName: "Addis Ababa 2",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AddisAbaba2, 0),
    },
    {
      field: "ArbaMinch",
      headerName: "Arba Minch",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.ArbaMinch, 0),
    },
    {
      field: "Assosa",
      headerName: "Assosa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Assosa, 0),
    },
    {
      field: "BahirDar",
      headerName: "Bahir Dar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.BahirDar, 0),
    },
    {
      field: "Dessie",
      headerName: "Dessie",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Dessie, 0),
    },
    {
      field: "DireDawa",
      headerName: "Dire Dawa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.DireDawa, 0),
    },
    {
      field: "Gambella",
      headerName: "Gambella",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Gambella, 0),
    },
    {
      field: "Gondar",
      headerName: "Gondar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Gondar, 0),
    },
    {
      field: "Hawassa",
      headerName: "Hawassa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Hawassa, 0),
    },
    {
      field: "Jigjiga",
      headerName: "Jigjiga",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Jigjiga, 0),
    },
    {
      field: "Jimma",
      headerName: "Jimma",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Jimma, 0),
    },
    {
      field: "KebriDehar",
      headerName: "Kebri Dehar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.KebriDehar, 0),
    },
    {
      field: "Mekele",
      headerName: "Mekele",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Mekele, 0),
    },
    {
      field: "NegeleBorena",
      headerName: "Negele Borena",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.NegeleBorena, 0),
    },
    {
      field: "Nekemte",
      headerName: "Nekemte",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Nekemte, 0),
    },
    {
      field: "Semera",
      headerName: "Semera",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Semera, 0),
    },
    {
      field: "Shire",
      headerName: "Shire",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Shire, 0),
    },],
  },
];


function HeaderWithStyle(props) {
  const {...params } = props;

  return (
      <strong>{params.headerName}</strong>
  );
}

let columnGroupingModel = [
  {
    headerName: '',
    children: [
      {
        field: "",
        headerName: "",
        width: 50,
        pinned: 'left',
        cellStyle: {'background-color': '#fafafb'}
      }
    ]
  },
  {
    headerName: 'Item Description',
    children: [
    {
      field: "ItemSN",
      headerName: "SN",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },
    {
      field: "Item",
      headerName: "Item",
      width: 250,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },
    {
      field: "Unit",
      headerName: "Unit",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },],
  },
  {
    headerName: '',
    children: [{
      field: "VEN",
      headerName: "VEN",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },],
  },
  {
    headerName: 'National',
    children: [{
      field: "NationalSOH",
      headerName: "SOH",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.NationalSOH, 0),
    },
    {
      field: "NationalDOS",
      headerName: "DOS",
      width: 60,
      valueGetter: (params) => FormatDecimal(params.data.NationalDOS, 0),
    },
    {
      field: "NationalAMC",
      headerName: "AMC",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.NationalAMC, 0),
    },
    {
      field: "MOHNationalAMC",
      headerName: "Adjusted AMC",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.MOHNationalAMC, 0),
    },
    {
      field: "NationalMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.NationalMOS, 0),
    },
    {
      field: "MOHNationalMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.MOHNationalMOS, 0),
      cellStyle: { whiteSpace: 'pre-wrap' }
    },],
  },
  {
    headerName: 'Git',
    children: [{
      field: "LCOpenedorCADReservedPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "LCOpenedorCADReservedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.LCOpenedorCADReservedQuantity, 0),
    },
    {
      field: "LCOpenedorCADReservedMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.LCOpenedorCADReservedMOS, 0),
    },
    {
      field: "LCOpenedorCADReservedMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.LCOpenedorCADReservedMOHMOS, 0),
    },],
  },
  {
    headerName: 'Prioritized POs',
    children: [{
      field: "PrioritizedPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "PrioritizedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.PrioritizedQuantity, 0),
    },
    {
      field: "PrioritizedMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.PrioritizedMOS, 0),
    },
    {
      field: "PrioritizedMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.PrioritizedMOHMOS, 0),
    },],
  },
  {
    headerName: 'Available POs',
    children: [{
      field: "AwaitingForeignCurrencyCashNotReservedPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "AwaitingForeignCurrencyCashNotReservedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AwaitingForeignCurrencyCashNotReservedQuantity, 0),
    },
    {
      field: "AwaitingForeignCurrencyCashNotReservedMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.AwaitingForeignCurrencyCashNotReservedMOS, 0),
    },
    {
      field: "AwaitingForeignCurrencyCashNotReservedMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AwaitingForeignCurrencyCashNotReservedMOHMOS, 0),
    },],
  },
  {
    headerName: 'TMD',
    children: [{
      field: "TMDPONumbers",
      headerName: "PO",
      width: 70,
    },
    {
      field: "TMDQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.TMDQuantity, 0),
    },
    {
      field: "TMDMOS",
      headerName: "MOS",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.TMDMOS, 0),
    },
    {
      field: "TMDMOHMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.TMDMOHMOS, 0),
    },],
  },
  {
    headerName: 'Requested order Quantity',
    children: [{
      field: "RequestedQuantity",
      headerName: "Quantity",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.RequestedQuantity, 0),
    },
    {
      field: "RequestedMOS",
      headerName: "Requested MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.RequestedMOS, 0),
    },
    {
      field: "RequestedMOHMOS",
      headerName: "Adjusted Requested MOS",
      width: 140,
      valueGetter: (params) => FormatDecimal(params.data.RequestedMOHMOS, 0),
    },],
  },
  {
    headerName: '',
    children: [{
      field: "TotalMOS",
      headerName: "Total MOS",
      width: 80,
      valueGetter: getTotalMOS,
    },
    {
      field: "ExpiryDates",
      headerName: "Expiry Dates",
      width: 240,
    },],
  },
  {
    headerName: 'Center',
    children: [{
      field: "CenterSOH",
      headerName: "SOH",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.CenterSOH, 0),
    },],
  },
  {
    headerName: 'Center to Hub',
    children: [{
      field: "CenterToHubGIT",
      headerName: "GIT",
      width: 100,
      valueGetter: (params) => FormatDecimal(params.data.CenterToHubGIT, 0),
    },],
  },
  {
    headerName: 'Hubs',
    children: [{
      field: "Adama",
      headerName: "Adama",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Adama, 0),
    },
    {
      field: "AddisAbaba",
      headerName: "Addis Ababa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AddisAbaba, 0),
    },
    {
      field: "AddisAbaba2",
      headerName: "Addis Ababa 2",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AddisAbaba2, 0),
    },
    {
      field: "ArbaMinch",
      headerName: "Arba Minch",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.ArbaMinch, 0),
    },
    {
      field: "Assosa",
      headerName: "Assosa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Assosa, 0),
    },
    {
      field: "BahirDar",
      headerName: "Bahir Dar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.BahirDar, 0),
    },
    {
      field: "Dessie",
      headerName: "Dessie",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Dessie, 0),
    },
    {
      field: "DireDawa",
      headerName: "Dire Dawa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.DireDawa, 0),
    },
    {
      field: "Gambella",
      headerName: "Gambella",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Gambella, 0),
    },
    {
      field: "Gondar",
      headerName: "Gondar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Gondar, 0),
    },
    {
      field: "Hawassa",
      headerName: "Hawassa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Hawassa, 0),
    },
    {
      field: "Jigjiga",
      headerName: "Jigjiga",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Jigjiga, 0),
    },
    {
      field: "Jimma",
      headerName: "Jimma",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Jimma, 0),
    },
    {
      field: "KebriDehar",
      headerName: "Kebri Dehar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.KebriDehar, 0),
    },
    {
      field: "Mekele",
      headerName: "Mekele",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Mekele, 0),
    },
    {
      field: "NegeleBorena",
      headerName: "Negele Borena",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.NegeleBorena, 0),
    },
    {
      field: "Nekemte",
      headerName: "Nekemte",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Nekemte, 0),
    },
    {
      field: "Semera",
      headerName: "Semera",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Semera, 0),
    },
    {
      field: "Shire",
      headerName: "Shire",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Shire, 0),
    },],
  },
];

let columnGroupingModelSAP = [
  {
    headerName: 'Item Description',
    children: [
    {
      field: "ItemSN",
      headerName: "SN",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },
    {
      field: "Item",
      headerName: "Item",
      width: 250,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },
    {
      field: "Unit",
      headerName: "Unit",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },],
  },
  {
    headerName: '',
    children: [{
      field: "VEN",
      headerName: "VEN",
      width: 70,
      pinned: 'left',
      cellStyle: {'background-color': '#dddddd'}
    },],
  },
  {
    headerName: 'National',
    children: [{
      field: "NationalSOH",
      headerName: "SOH",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.NationalSOH, 0),
    },
    {
      field: "NationalDOS",
      headerName: "DOS",
      width: 60,
      valueGetter: (params) => FormatDecimal(params.data.NationalDOS, 0),
    },
    {
      field: "NationalAMC",
      headerName: "AMC",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.NationalAMC, 0),
    },
    {
      field: "MOHNationalAMC",
      headerName: "Adjusted AMC",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.MOHNationalAMC, 0),
    },
    {
      field: "NationalMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.NationalMOS, 0),
    },
    {
      field: "MOHNationalMOS",
      headerName: "Adjusted MOS",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.MOHNationalMOS, 0),
      cellStyle: { whiteSpace: 'pre-wrap' }
    },],
  },
  {
    headerName: 'Contract',
    children: [
    {
      field: "ContractQuantity",
      headerName: "Quantity",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.ContractQuantity, 0)
    },
    {
      field: "ContractMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.ContractMOS, 0)
    }
    ]
  },
  {
    headerName: 'Ordered',
    children: [
    {
      field: "OrderedQuantity",
      headerName: "Quantity",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.OrderedQuantity, 0)
    },
    {
      field: "OrderedMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.OrderedMOS, 0)
    }
    ]
  },
  {
    headerName: 'Shipped',
    children: [
    {
      field: "ShippedQuantity",
      headerName: "Quantity",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.ShippedQuantity, 0)
    },
    {
      field: "ShippedMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.ShippedMOS, 0)
    }
    ]
  },
  {
    headerName: 'Delivered',
    children: [
    {
      field: "DeliveredQuantity",
      headerName: "Quantity",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.DeliveredQuantity, 0)
    },
    {
      field: "DeliveredMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.DeliveredMOS, 0)
    }
    ]
  },
  {
    headerName: 'Invoiced',
    children: [
    {
      field: "InvoicedQuantity",
      headerName: "Quantity",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.InvoicedQuantity, 0)
    },
    {
      field: "InvoicedMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.InvoicedMOS, 0)
    }
    ]
  },
  {
    headerName: 'Quantity Left',
    children: [
    {
      field: "QuantityLeft",
      headerName: "Quantity",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.QuantityLeft, 0)
    },
    {
      field: "QuantityLeftMOS",
      headerName: "MOS",
      width: 80,
      valueGetter: (params) => FormatDecimal(params.data.QuantityLeftMOS, 0)
    }
    ]
  },
  {
    headerName: '',
    children: [
    // {
    //   field: "TotalMOS",
    //   headerName: "Total MOS",
    //   width: 80,
    //   valueGetter: getTotalMOS,
    // },
    {
      field: "ExpiryDates",
      headerName: "Expiry Dates",
      width: 240,
    },],
  },
  {
    headerName: 'Center',
    children: [{
      field: "CenterSOH",
      headerName: "SOH",
      width: 70,
      valueGetter: (params) => FormatDecimal(params.data.CenterSOH, 0),
    },],
  },
  {
    headerName: 'Center to Hub',
    children: [{
      field: "CenterToHubGIT",
      headerName: "GIT",
      width: 100,
      valueGetter: (params) => FormatDecimal(params.data.CenterToHubGIT, 0),
    },],
  },
  {
    headerName: 'Hubs',
    children: [{
      field: "Adama",
      headerName: "Adama",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Adama, 0),
    },
    {
      field: "AddisAbaba",
      headerName: "Addis Ababa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AddisAbaba, 0),
    },
    {
      field: "AddisAbaba2",
      headerName: "Addis Ababa 2",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.AddisAbaba2, 0),
    },
    {
      field: "ArbaMinch",
      headerName: "Arba Minch",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.ArbaMinch, 0),
    },
    {
      field: "Assosa",
      headerName: "Assosa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Assosa, 0),
    },
    {
      field: "BahirDar",
      headerName: "Bahir Dar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.BahirDar, 0),
    },
    {
      field: "Dessie",
      headerName: "Dessie",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Dessie, 0),
    },
    {
      field: "DireDawa",
      headerName: "Dire Dawa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.DireDawa, 0),
    },
    {
      field: "Gambella",
      headerName: "Gambella",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Gambella, 0),
    },
    {
      field: "Gondar",
      headerName: "Gondar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Gondar, 0),
    },
    {
      field: "Hawassa",
      headerName: "Hawassa",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Hawassa, 0),
    },
    {
      field: "Jigjiga",
      headerName: "Jigjiga",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Jigjiga, 0),
    },
    {
      field: "Jimma",
      headerName: "Jimma",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Jimma, 0),
    },
    {
      field: "KebriDehar",
      headerName: "Kebri Dehar",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.KebriDehar, 0),
    },
    {
      field: "Mekele",
      headerName: "Mekele",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Mekele, 0),
    },
    {
      field: "NegeleBorena",
      headerName: "Negele Borena",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.NegeleBorena, 0),
    },
    {
      field: "Nekemte",
      headerName: "Nekemte",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Nekemte, 0),
    },
    {
      field: "Semera",
      headerName: "Semera",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Semera, 0),
    },
    {
      field: "Shire",
      headerName: "Shire",
      width: 90,
      valueGetter: (params) => FormatDecimal(params.data.Shire, 0),
    },],
  },
];

const MISCELLANEOUS_STOCK_PARAM = {
  ModeCode: "RDF",
};

const MISCELLANEOUS_STOCK_DETAIL_PARAM = {
  ModeCode: "RDF",
};


const MiscellaneousStockReport = () => {
  // dialog
  const [filterParamsDialog, setFilterParamsDialog] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = (itemSN, modeCode) => {
    setFilterParamsDialog ( { ItemSN: itemSN, ModeCode: modeCode });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  columnGroupingModel[0].children[0].cellRenderer = (param) => {
    return (
      <div>
        <FaExpandAlt
          className="expand-icon-2"
          title="show detail"
          onClick={() => handleOpen(param?.data?.ItemSN, param?.data?.ModeCode)}
        />
      </div>
    );
  };

  // end dialog
  const [commodityType, setCommodityType] = useState(null);
  const history = useNavigate();
  const [program, setProgram] = useState(null);
  const [modeType, setModeType] = useState('RDF');
  const [filterParams, setFilterParams] = useState({...MISCELLANEOUS_STOCK_PARAM});
  const [modeList] = useState([{key: 'RDF', value: 'RDF'}, {key: 'Health Program', value: 'HPR'}]);
  const [commodityList, setCommodityList] = useState([{ key:'-- Commodity Type --', value:'' }]);
  const [programList, setProgramList] = useState([{ key:'-- Program --', value:'' }]);

  useEffect(() => {
    getCommodityList().then((data) => {
      setCommodityList([...commodityList, ...(data.data.Data.map((d) => {return {key: d.CommodityType, value: d.CommodityTypeCode}}))]);
    });
    getProgramList().then((data) => {
      setProgramList([...programList, ...(data.data.Data.map((d) => {return {key: d.Program, value: d.ProgramCode}})).filter((item) => !(item.value !== 'AC' && item.value !== 'KDN' && item.value !== 'RDF'))]);
    });
  }, []);

  useEffect(() => {
    setFilterParams({...filterParams, ...(modeType && {ModeCode: modeType}), ...(commodityType && {CommodityTypeCode: commodityType}), ...(program && {ProgramCode: program}),});
  }, [commodityType, program, modeType]);

  
  const [currentTab, setCurrentTab] = useState("Vitas");
  const [tabs, setTabs] = useState(["Vitas", "ERP"]);

  function changeTab(tab) {
    setCurrentTab(tab);
  }
  
  return (
    <Wrapper>
      {" "}
      <Modal className="rs-dialog"
              open={open} onClose={handleClose}>
        { filterParamsDialog.ItemSN !== null && <Box className="rs-d-container">
          <CustomDataGrid
            component_config={{
              title: "National Stock Status and Pipeline Report - Item Activity Breakdown",
              data_source_api: MISCELLANEOUS_STOCK_DETAIL_API,
              data_property: "Data",
              columns: columnGroupingDetailModel,
              isSOH: false,
              use_default_filter_params: true,
              select_filter_config: [ ],
              isNationalDetail: true,
              show_search: false,
              defaultFilterParams: {...filterParamsDialog}
            }}
          />
        </Box>}
      </Modal>
      <NavBar title={`Fanos National Stock Status and Pipeline Dashboard`} />
      <div className="grid-section">
        <div className="grid" style={{ padding: '2rem'}}>
          <div className="flex-row" style={{ justifyContent: 'space-between'}}>
            <div className="flex-1">
              <Button type="submit" 
                style={{ 
                  backgroundColor: '#15616d',
                  marginBottom: 10,
                }}
                mb={4}
                variant="contained" 
                onClick={() => {
                history('/NationalAMC')
              }}>
                National AMC
                &nbsp;
                <FaExternalLinkAlt />
              </Button>
            </div>
            <div className="flex-1">
              <ul className="flex-row tab-ul-admin">
                { tabs.map(function (tab) {
                    return (<li className={`tab-li ${ currentTab === tab 
                        ? 'tab-li-active' : null }`}>
                      <a onClick={() => changeTab(tab)}>{tab}</a>
                    </li>
                    );
                })}
              </ul>
            </div>
          </div>
          
          { currentTab == "Vitas" && <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "National Stock Status and Pipeline Report",
              data_source_api: MISCELLANEOUS_STOCK_API,
              data_property: "Data",
              columns: columnGroupingModel,
              isSOH: false,
              show_search: true,
              isNational: true,
              wgInfoId: 'wgMohRrfStockStatusWithPipeline',
              customExport: true,
              pageSize: 25,
              use_default_filter_params: false,
              select_filter_config: [NATIONAL_COMMODITY_TYPE_SELECT_CONFIG, NATIONAL_PROGRAM_SELECT_CONFIG, NATIONAL_MODE_SELECT_CONFIG],
              defaultFilterParams: {...filterParams},
              columnGroupingModel: columnGroupingModel,
              pinnedColumns: { left: ['ItemSN', 'Item', 'Unit']}
            }}
          />
          }

          { currentTab == "ERP" &&<CustomDataGrid
            component_config={{
              width: "90vw",
              title: "National Stock Status and Pipeline Report",
              data_source_api: MISCELLANEOUS_STOCK_API_SAP,
              data_property: "Data",
              columns: columnGroupingModelSAP,
              isSOH: false,
              show_search: true,
              isNational: true,
              wgInfoId: 'wgMohRrfStockStatusWithPipeline',
              customExport: true,
              pageSize: 25,
              use_default_filter_params: false,
              select_filter_config: [NATIONAL_COMMODITY_TYPE_SELECT_CONFIG, NATIONAL_PROGRAM_SELECT_CONFIG, NATIONAL_MODE_SELECT_CONFIG],
              defaultFilterParams: {...filterParams},
              columnGroupingModel: columnGroupingModel,
              pinnedColumns: { left: ['ItemSN', 'Item', 'Unit']}
            }}
          />
          }
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .MuiDataGrid-columnHeader {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: GhostWhite;
    border: solid 2px;
  }
  .MuiDataGrid-columnHeaderTitleContainerContent {
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.01071em;
  }
  .MuiDataGrid-columnHeaderTitleContainer {
    border-bottom-width: 0px;
    flex: initial !important;
  }
  .MuiDataGrid-columnHeaderDraggableContainer{
    justify-content: center;
  }

  .tab-ul-admin {
    list-style-type: none;
    align-items: center;
    border-radius: 0.3rem;
    background-color: rgb(237, 241, 249);
    padding: 0;
    max-width: 30rem;
    min-width: 20rem;
  }
  
  .tab-li {
    flex-grow: 1;
  }
  
  .tab-li a {
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 0.15rem;
    padding: 0.35rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.15rem;
  }
  
  .tab-li-active a {
    background: white;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px 0px;
  }
  
`;

export default MiscellaneousStockReport;
