import React, { useEffect } from "react";
import NavBar from "../shared/NavBar";
import "../shared/Charts/Charts.css";
import styled from "styled-components";
import Thumbnail from "../../Packages/DynamicWidget/Widgets/Thumbnail";


import {StackedBarChart} from "../shared/Charts/StackedBarChart";
import GaugeCard from "../shared/Shared Utils/GaugeCard";
import moment from "moment";

// Icons
import { MdLocationPin, MdCoronavirus, MdLocalHospital, MdEventAvailable, MdOutlineLockOpen } from "react-icons/md";
import { IoEnterOutline } from "react-icons/io5";
import { TbRecycle, TbBuildingWarehouse, TbCalendar   } from "react-icons/tb";
import { GiHeartPlus } from "react-icons/gi";
import { FaShieldAlt } from "react-icons/fa";
import { HiShieldCheck } from "react-icons/hi";

import {
  getEnvironemntList,
} from "../../Services/ApiManager";


import { useNavigate,} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./Program/style.css";
import CustomLandingCard from "../shared/Shared Utils/CustomLandingCard";
import MapCard from "../shared/Shared Utils/MapCard";
import GlobalMapCard from "../shared/Shared Utils/GlobalMapCard";
import Maps from "./Program/Maps";
import CustomCardV3 from "../../Packages/DynamicWidget/Widgets/CustomCardV3";

export default function Home() {
  const history = useNavigate();
  const homeThumbnails = 
    [
      {
        title: "Program",
        type: "link",
        img_src: "program.png",
        tooltip: "Program",
        Icon: GiHeartPlus,
        url: "/Programs/FamilyPlanning-Family Planning",
        url_data: { ProgramGroupID: 805, ProgramID: 94, ProgramName: "Family Planning", GroupID: 27, GroupName: "Administrator", ProgramCode: "FP", label: "Family Planning" }
      },
      {
        title: "Location",
        type: "link",
        tooltip: "Location",
        Icon: MdLocationPin,
        url: "/Locations/HomeOffice",
        url_data: { RowNumber: 12, Environment: "Home Office", EnvironmentCode: "CNPH", EnvironmentGroup: "EPSS", EnvironmentGroupCode: "HUB", CommonName: "HomeOffice", EnvironmentID: 4, label: "Home Office" }
      },
      {
        title: "ERIS",
        type: "link",
        tooltip: "Regulatory",
        Icon: HiShieldCheck,
        url: "/eRIS"
      },
      {
        title: "AEFI",
        type: "link",
        tooltip: "Regulatory",
        Icon: HiShieldCheck,
        url: "/AEFI"
      },
      {
        title: "National Stock Status",
        type: "link",
        img_src: "national-stock-status.png",
        tooltip: "Inbound",
        Icon: IoEnterOutline,
        url: "/MiscellaneousStockReport"
      },
      {
        title: "Inbound",
        type: "link",
        tooltip: "Inbound",
        Icon: IoEnterOutline,
        url: "/InboundReport"
      },
      {
        title: "Shipment",
        type: "link",
        tooltip: "Inbound",
        Icon: IoEnterOutline,
        url: "/ShipmentStatus"
      },
      {
        title: "Tender and Contract",
        type: "link",
        img_src: "tender-and-contract.png",
        tooltip: "Inbound",
        Icon: IoEnterOutline,
        url: "/Procurement"
      },
      // Facility
      {
        title: "Facility",
        type: "link",
        tooltip: "Facility",
        Icon:MdLocalHospital,
        url: "/FacilityDashboard"
      },
      {
        title: "RRF",
        type: "external",
        tooltip: "Facility",
        Icon:MdLocalHospital,
        url: "https://rrf.fanosonline.org/#/login/app.tracking",
        passUserData: true
      },
      // COVID
      // {
      //   title: "COVID",
      //   type: "link",
      //   img_src: "covid.png",
      //   tooltip: "COVID",
      //   Icon: MdCoronavirus,
      //   url: "/Covid19"
      // },
      // Public Reports
      // {
      //   title: "Product Availability",
      //   type: "external",
      //   tooltip: "Public",
      //   Icon: MdOutlineLockOpen,
      //   url: "https://csm.fanosonline.org/product-availability",
      //   img_src: "product-availability.png"
      // },
      // {
      //   title: "Local PO",
      //   type: "link",
      //   tooltip: "Public",
      //   Icon: MdOutlineLockOpen,
      //   url: "/LocalPO",
      //   img_src: "local-po.png"
      // },
      // Routine Reports
      {
        title: "Hub Vital Report",
        type: "link",
        tooltip: "Routine Reports",
        Icon: TbRecycle,
        url: "/HubVitalReport",
        img_src: "hub-vital-report.png"
      },
      {
        title: "Item Distribution",
        type: "link",
        tooltip: "Routine Reports",
        Icon: TbRecycle,
        url: "/ItemDistribution",
        img_src: "item-distribution.png"
      },
      {
        title: "Medical Equipment",
        type: "link",
        tooltip: "Routine Reports",
        Icon: TbRecycle,
        url: "/MedicalEquipmentDistribution",
        img_src: "medical-equipment-distribution.png"
      }
    ];
  return (
  <Wrapper>
    <NavBar style={{color: "black"}} title='Fanos Dashboard' />
    <div className="section-container"> 
      <div className="flex-row" style={{ alignItems: "stretch" }}>
        <div style={{flex: "0 0 58%"}}>
          <div className="flex-row">
            <CustomCardV3
              widget_config={{                  
                page: "dashboard",
                title: "Hubs",
                description: "EPSS branches including home office",
                value: "20",
                type: "EPSS",
                accent_color: "rgb(21, 97, 109)",
              }}
              Icon={TbBuildingWarehouse}
            />
            <CustomCardV3
              widget_config={{                  
                page: "dashboard",
                title: "Annual Procurement Amount",
                description: "Total value of medical goods procured in the fiscal year",
                value: "~$960M",
                type: "EPSS",
                accent_color: "rgb(21, 97, 109)",
              }}
              Icon={TbCalendar  }
            />
          </div>
            <div style={{margin: "0.5rem", boxShadow: "0 4px 6px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.08)"}}>
              <CustomLandingCard
                title={"Number of Institutions Served"}
                iconName={"Facilities"}
                cardType={"Facility"}
              />
            </div>
        </div>
          
        <div style={{margin: "0.5rem", flex: "0 0 42%", 
            boxShadow: "0 4px 6px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.08)", 
            borderRadius: "0.5rem", background: "white"}}>
          <MapCard
            title={"EPSS hubs "}
            pinColor={"#D76C05"}
          />
        </div>
      </div>

      <div className="flex-row" style={{ alignItems: "stretch" }}>
        <div className="grow-1" style={{margin: "0.5rem"}}>
          <GaugeCard />
        </div>
        <div className="grow-2">
        <div className="flex-column">
          
        <div className="flex-row">
            <div className="grow-1" style={{margin: "0.5rem", boxShadow: "0 4px 6px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.08)"}}>
              <CustomLandingCard
                title={"Average Order Leadtime (National)"}
                iconName={"Days"}
                value={"6.2B"}
                description={"Average time to complete an order"}
                cardType={"LeadTime"}
              />
            </div>
            <div className="grow-1" style={{margin: "0.5rem", boxShadow: "0 4px 6px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.08)"}}>
              <CustomLandingCard
                title={"Product Availability"}
                description={"The comparison between " + moment().subtract(1, 'month').format('MMMM') + " and " + moment().format('MMMM')}
                cardType={"National"}
              />
            </div>
          </div>  
          <div style={{margin: "0.5rem", boxShadow: "0 4px 6px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.08)"}}> 
            <StackedBarChart />
          </div>
        </div>
        </div>
      </div>
    </div>

    <div className="section-container"> 
      <div style={{minWidth: "96vw", maxWidth: "100%"}}>
        <Maps 
            param={{ProgramCode: 'FP'}} 
            modeCodeParam={{ModeCode: 'HPR'}} 
            page={"Program"} 
            map_width={'100%'}
            map_height={'33rem'}
            hide_header={true}
            header_title={"title"}/>

      </div>
    </div>
    <GridContainer>
      <div className="flex-row flex-wrap thumbnail" style={{minWidth: "96vw"}}>
        {homeThumbnails?.map(function (config) {
          return (
            <Thumbnail
              widget_config={config}
              Icon={config.Icon}
            />
          );
        })}
      </div>
    </GridContainer>
  </Wrapper>)
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #F0F3F4;
  .section-container {
    margin: 0 1rem;
  }
  .container-box {
    background: #fff;
    width: 100%;
    padding: 50px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 4rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }
  .landing-title {
    display: flex;
    font-size: 32px;
    justify-content: center;
    font-weight: 600;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;

