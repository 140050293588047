import Axios from "axios";
import { toEthiopian } from "ethiopian-date";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";
import moment from "moment";
import cachedApi from "./cachedApi";

const daguUrl = "https://dagudashboardbe.k8s.sandboxaddis.com/api";
const kpiUrl = "https://kpi.dev.api.fanosonline.org/api";

Axios.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let token = userInfo?.Key;
    if (config.url.includes(daguUrl)) {
      const daguToken = localStorage.getItem("daguToken");
      if (!config.url.includes("Login"))
        config.headers["Authorization"] = "Bearer " + daguToken;
    } else if (token) {
      config.headers["Authorization"] = "Basic " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
const { REACT_APP_API_ENDPOINT } = process.env;

export const loginRequest = async (data) => {
  const response = await Axios.post(
    REACT_APP_API_ENDPOINT + "/AccountManager/AuthenticateWithPlatformIdentifiera",
    null,
    {
      params: {
        deviceIdentifier: data.deviceIdentifier,
        deviceType: data.deviceType,
        environmentCode: data.environmentCode,
        hcmisVersion: data.hcmisVersion,
        password: data.password,
        userName: data.username,
      },
    }
  );
  return response.data;
};

export const loginRequestDagu = async () => {
  const response = await Axios({
    method: 'post',
    url: daguUrl + "/Account/Login",
    headers: {}, 
    data: {
      username:'fanosAdmin', password:'Fanos@123'
    }
  });
  return response.data.token;
};

export const getFanosData = async (api, params, dataSource, method) => {
  method = method ? method : "get";
  var currrentBaseUrl = "";
  switch (dataSource) {
    case "dagu":
      currrentBaseUrl = daguUrl;
      break;
    default:
      currrentBaseUrl = REACT_APP_API_ENDPOINT ;
      break;
  }

  if(method == 'POST' || dataSource == 'dagu') {
    return await Axios({
      method: method,
      url: currrentBaseUrl + api,
      params: method == 'POST'? null: params,
      data: method == 'POST'? params: null,
    });
  }
  else {
    return await cachedApi.get(api, { params });
  }
};

export const fetchHubsList = async () => {
  const response = await Axios.get(REACT_APP_API_ENDPOINT  + "/EN_WebApi/ByEnvironmentCode");
  return response;
};

export const getEnvironemntList = async (EnvCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/EN_WebApi/ByEnvironmentCode",
    params: EnvCode
      ? { EnvironmentCode: EnvCode }
      : {
          EnvironmentGroupCode: "hub",
        },
  });

  return response;
};

export const searchByEnvironment = async (searchTerm, site) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/SS_WebApi/ItemProgramFilter",
    params: {
      Name: searchTerm,
      EnvironmentCode: site,
      Type: "I",
    },
  });

  return response;
};

export const search = async (searchTerm, site) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/SS_WebApi/ItemProgramFilter?pageNo=1&pageSize=10",
    params: searchTerm? {
      Name: searchTerm,
      Type: "I",
    }:
    {
    Type: "I",
    },
  });

  return response;
};

export const getProgramGroup = async (userId) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminGetAllProgramGroup",
    params: {
      UserID: userId,
    },
  });
  return response;
};

export const getHeaderInformation = async (modeCode, programCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/SS_webApi/NationalValues",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
    },
  });
  return response;
};
export const getQuarterReportSummary = async (params) => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/PFSAQuarterReport/PFSAQuarterReportSummaryStaging${!params.EnvironmentCode? "?EnvironmentCode" : ""}`,
    params: params,
  });
  return response;
};
export const getShiHeader = async (modeCode, programCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/RCD_WebApi/SHIHeader",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
    },
  });
  return response;
};
export const getHeaderInformationByItem = async (modeCode, ProductSN) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/SS_webApi/NationalValues",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
    },
  });
  return response;
};
export const getProgramItemsNation = async (modeCode, programCode, OrderBy) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/IUBVIUI_WebApi/ProgramItemsNationalSOH",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: OrderBy,
    },
  });
  return response;
};
export const getProductAvailablity = async (EnvironmentCode, modeCode) => {
  const response = await Axios({
    method: "get",
    url: EnvironmentCode
      ? REACT_APP_API_ENDPOINT  +
        `/CommodityProductAvailability/GetCommodityProductAvailability?EnvironmentCode=${EnvironmentCode}&ModeCode`
      : REACT_APP_API_ENDPOINT  +
        "/CommodityProductAvailability/GetCommodityProductAvailability?EnvironmentCode&ModeCode",
    param: {
      EnvironmentCode: EnvironmentCode,
      ModeCode: modeCode,
    },
  });
  return response;
};
export const getProductAvailablityByContext = async (ProductSN) => {
  const response = await Axios({
    method: "get",
    url:
      REACT_APP_API_ENDPOINT  + "/CommodityProductAvailability/ProductAvailableByEnvironment",
    param: {
      ProductSN: ProductSN,
    },
  });
  return response;
};
export const getWidgetInfo = async (widgetId) => {
  const response = await Axios({
    method: "get",
    url:
      REACT_APP_API_ENDPOINT  + `/WidgetInformation/GetWidget?wgid=${widgetId}`
  });
  return response;
};
export const publishWidgetInfo = async (widgetData) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + `/WidgetInformation/UpdateWidget`,
    data: widgetData,
  });
  return response;
};
export const getCommodityList = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/CT_WebApi/CommodityList",
  });
  return response;
};
export const getProgramList = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/RRFSSNDPPLN_WebApi/MOHRRFProgramsList",
  });
  return response;
};
export const getShipmentList = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/RRFSSNDPPLN_WebApi/ShipmentDwellTimeReport",
  });
  return response;
};
export const getBeneficiariesByGender = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/AEFI_WebApi/BeneficiariesByGender",
    params,
  });
  return response;
};
export const getBeneficiariesByAgeGroup = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/AEFI_WebApi/BeneficiariesByAgeGroup",
     params,
  });
  return response;
};
export const getAdverseByGender = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/AEFI_WebApi/AdverseEventsByGender",
    params,
  });
  return response;
};
export const getAdverseByAgeGroup = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/AEFI_WebApi/AdverseEventsByAgeGroup",
     params,
  });
  return response;
};
export const getAdverseByVaccineType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/AEFI_WebApi/AdverseEventsByVaccineType",
    params,
  });
  return response;
};
export const getAdverseByOccurrence = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/AEFI_WebApi/AdverseEventsByOccurrencePeriod",
    params,
  });
  return response;
};
export const getCommonAdverseEvents = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/AEFI_WebApi/MostCommonAdverseEvents",
    params,
  });
  return response;
};
export const getFacilityByProductType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/eRIS_WebApi/CertifiedFacilitiesCountByProductType",
    params,
  });
  return response;
};
export const getFacilityByType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/eRIS_WebApi/CertifiedFacilitiesCountByFacilityType",
    params,
  });
  return response;
};
export const getImportedAmountYearly = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/eRIS_WebApi/ImportedAmountYearlyTrend",
    params,
  });
  return response;
};
export const getImportedProducts = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/eRIS_WebApi/ItemsListByAmount",
    params,
  });
  return response;
};
export const getTopImporters = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/eRIS_WebApi/ImportersListByAmount",
    params,
  });
  return response;
};
export const getTopApplicants = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/eRIS_WebApi/ApplicantsListByItemCount",
    params,
  });
  return response;
};
export const getDaguFacilitySyncStatus = async () => {
  const response = await Axios({
    method: "get",
    url: `${daguUrl}/Dashboard/GetFacilitySyncStatus`,
  });
  return response;
};

export const getDaguFacilityTotalCount = async () => {
  const response = await Axios({
    method: "get",
    url: `https://jsi-odk-micro.onrender.com/daguMasterList`,
  });
  return response;
}

export const getMapFacilityByProgram = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/GIS/GetFacilitiesByProgram?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getNumberOfFacilitiesServed = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/OIH_WebApi/NoOfInstitution?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getFacilityMapGridData = async (program, mode, institutionID) => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/OIH_WebApi/ItemDistributionHub2Facility?ProgramCode=${program["ProgramCode"]}&ModeCode=${mode["ModeCode"]}&InstitutionID=${institutionID}&Page=1&PageSize=10`,
  });
  return response;
};

export const getLocalSupplyRoutes = async (param) => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/GIS/LocalSupplyRoutes`,
    params: {...param}
  });
  return response;
};

export const getGlobalSupplyBreakDown = async (param) => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/GIS/GlobalSupplyRouteBreakdown`,
    params: {...param}
  });
  return response;
};

export const getGlobalSupplyRoutes = async (param) => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/GIS/GlobalSupplyRoutes`,
    params: {...param}
  });
  return response;
};

export const getThematicByRegion = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/GIS/DistributionByRegionThematic?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getThematicByZone = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/GIS/DistributionByZoneThematic?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getThematicByWoreda = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/GIS/DistributionByWoredaThematic?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getHeatMapByRegion = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/OIH_WebApi/ByR_155?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getHeatMapByZone = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/OIH_WebApi/ByZ_156?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getHeatMapByWoreda = async () => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/OIH_WebApi/ByD_157?ModeCode=HPR&ProgramCode=FP`,
  });
  return response;
};

export const getMapDetail = async (InstitutionID) => {
  const response = await Axios({
    method: "get",
    url: `${REACT_APP_API_ENDPOINT }/OID_WebApi/OID_ByIUINTotalAggrigate?InstitutionID=${InstitutionID}&OrderBy=QuantityIssued+DESC&Page=1`,
  });
  return response;
};

export const getFacilitiesServed = async (year) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/StagingReports/FacilitiesServedByYear",
    params: year? {
      FiscalYear: year,
    }: {},
  });
  return response;
};

export const getHubLeadTime = async (year) => {
  const response = await Axios({
    method: "get",
    url: "https://kpi.dev.api.fanosonline.org/api/KPI/GetTimeFromPOToSTV",
    params: {
      dateTo: moment().format('YYYY-MM-DD'),
      kpiId: 9,
    },
  });
  return response;
};

export const getHubsLocation = async (userId) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/StagingReports/HubsLocation",
  });
  return response;
};
export const getJobTitles = async (userId) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/UA_WebApi/JobTitles?OrderBy=JobTitle+asc",
  });
  return response;
};
export const getEnvironmentListWithOutGroup = async (userId) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/EN_WebApi/ByEnvironmentCode?ModeCode=HPR",
  });
  return response;
};
export const getAdminAllGroups = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminGetAllGroups",
  });
  return response;
};
export const getAdminAllPages = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminGetAllPages",
  });
  return response;
};
export const getAdminProgramList = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/SS_WebApi/ProgramList",
  });
  return response;
};
export const createNewUser = async (user) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/UA_WebApi/InsertUser",
    data: [user]
  });
  return response;
};
export const createGroup = async (group) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminAddGroup",
    data: [group]
  });
  return response;
};
export const createPages = async (page) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminAddPages",
    data: [page]
  });
  return response;
};
export const updateUsers = async (user) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/UA_WebApi/UpdateUser",
    data: [user]
  });
  return response;
};
export const updateGroup = async (group) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminUpdateGroup",
    data: [group]
  });
  return response;
};
export const updatePages = async (page) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminUpdatePages",
    data: [page]
  });
  return response;
};

export const getPMDProductAvailablity = async (user) => {
  const response = await Axios({
    method: "get",
    url: kpiUrl + "/KPI/GetPMDProductAvailability",
    params: {
      kpiId: "2",
      dateFrom: "2023-07-08",
      dateTo: moment().date(1).subtract(1, 'day').format('YYYY-MM-DD'),
    },
  });
  return response;
};

export const getEndToEndSupplyChain = async (year) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/StagingReports/POToHubSOH",
    params: {
      FiscalYear: year,
    },
  });
  return response;
};

export const getCurrentFiscalYear = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/LookUp/FiscalYearList",
  });
  return response;
};

export const getInventoryTurnOverRate = async () => {
  const response = await Axios({
    method: "get",
    url: "https://kpi.dev.api.fanosonline.org/api/KPI/GetInventoryTurnoveRate",
    params: {
       dateTo: moment().format('YYYY-MM-DD'),
       fiscalYear: '2015',
       kpiId: "10",
    },
  });
  return response;
};

export const getOrderFillRate = async () => {
  const response = await Axios({
    method: "get",
    url: "https://kpi.dev.api.fanosonline.org/api/KPI/GetOrderFillRate",
    params: {
       dateTo: moment().format('YYYY-MM-DD'),
       kpiId: "8",
    },
  });
  return response;
};

export const getPMDWastageRate = async () => {
  const response = await Axios({
    method: "get",
    url: "https://kpi.dev.api.fanosonline.org/api/KPI/GetPMDWastageRate",
    params: {
       dateTo: moment().format('YYYY-MM-DD'),
       kpiId: "4",
    },
  });
  return response;
};

export const getPMDMode19Collection = async () => {
  const response = await Axios({
    method: "get",
    url: "https://kpi.dev.api.fanosonline.org/api/KPI/GetPMDModel19Collection",
    params: {
       dateTo: moment().format('YYYY-MM-DD'),
       kpiId: "19",
    },
  });
  return response;
};

export const sendSOHToEmail = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/StagingReports/SendSOHDataToEmail?" + params,
  });
  return response;
};

export const sendSOHEmailByActivity = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/StagingReports/SendSOHByActivityDataToEmail?" + params,
  });
  return response;
};

export const getPageListByGroup = async (GroupID) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminGetAllGroupPages",
    params: {UserID: GroupID},
  });
  return response;
};

export const getProgramListByGroup = async (GroupID) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminGetAllProgramGroup",
    params: {UserID: GroupID},
  });
  return response;
};

export const adminAddGroupPages = async (newPages) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminAddGroupPages",
    data: newPages,
  });
  return response;
};

export const adminAddProgramGroup = async (newGroups) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminAddProgramGroup",
    data: newGroups,
  });
  return response;
};

export const adminDeleteProgramGroup = async (revoke) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminDeleteProgramGroup",
    data: revoke,
  });
  return response;
};

export const adminDeleteGroupPages = async (revoke) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/Permission/adminDeleteGroupPages",
    data: revoke,
  });
  return response;
};

export const saveNationalAMC = async (data) => {
  const response = await Axios({
    method: "post",
    url: REACT_APP_API_ENDPOINT  + "/RRFSSNDPPLN_WebApi/AddItemAMC",
    data: data
  });
  return response;
};

const requests = [
  getFanosData,
  getEnvironemntList,
  loginRequest,
  getProgramGroup,
  getHeaderInformation,
  getProgramItemsNation,
  getHeaderInformationByItem,
  getProductAvailablity,
  getProductAvailablityByContext,
  getWidgetInfo,
];

export default requests;
